import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { EthInfo } from 'components/RegisterEthereum'
import Text from 'components/Text/Text.styles'
import {
  Button,
  RefreshButton,
  FAQ,
  SectionTitle,
  ComponentWrapper,
  ComponentScroll,
  CryptoAddressWrapper,
  CryptoAddress,
} from '@stokr/components-library'

import { ModalWrapper } from 'components/Modal/Modal.styles'
import {
  getEtherBalance,
  isMetamaskInstalled,
  getMetamaskAddress,
  getEtherPrice,
} from 'api/StokrWeb3'
import { toFixed } from 'core-js/fn/number/epsilon'

class EthAddressMetamask extends PureComponent {
  state = {
    correct: false,
    refresh: false,
    failed: false,
    address: '0x0000000000000000000000000000000000000000',
    data: {
      value: '0',
      unit: 'ETH',
      eqValue: '0',
      eqUnit: '€',
    },
  }

  async getBalanceData(address) {
    const tokenBalances = await getEtherBalance()
    var etherPrice = null

    try {
      etherPrice = await getEtherPrice()
    } catch (error) {
      console.log('🚀 ~ error', error)
      etherPrice = null
    }

    var ethAddress = tokenBalances.find((x) => x.account === address)
    let data = {}
    if (etherPrice && ethAddress) {
      data = {
        value: ethAddress.balance.toFixed(4),
        unit: 'ETH',
        eqValue: (ethAddress.balance * etherPrice.EUR).toFixed(2),
        eqUnit: '€',
      }
    }

    this.setState({ data: data })
  }

  async initMetamask() {
    if (isMetamaskInstalled()) {
      try {
        const address = await getMetamaskAddress()
        console.log('address: ', address)
        if (address) {
          await this.getBalanceData(address)

          this.setState({ failed: false, correct: true, address: address })

          window.ethereum.on('accountsChanged', async (accounts) => {
            const address = await getMetamaskAddress()
            this.setState({ address: address })
            await this.getBalanceData(address)

            this.setState({ refresh: true }, () => {
              this.setStateDelayed({ refresh: false }, 2000)
            })
          })
        }
      } catch (error) {
        console.log(error)
        this.setState({ failed: true, correct: false })
      }
    }
  }

  async componentDidMount() {
    await this.initMetamask()
  }

  setStateDelayed = (newState, time) => {
    this.timeout = setTimeout(() => {
      this.setState(newState)
    }, time)
  }

  onRefreshClick = async () => {
    await this.initMetamask()

    this.setState(
      {
        //correct: false,
        refresh: true,
        //failed: false
      },
      () => {
        this.setStateDelayed({ refresh: false }, 2000)
      },
    )
  }

  onRegisterClick = () => {
    const { correct, address } = this.state
    const { changeStep, setAddressToRegister } = this.props

    setAddressToRegister(address)

    correct && changeStep()
  }

  render() {
    const { correct, refresh, failed, address, data } = this.state

    return (
      <ModalWrapper>
        <ComponentWrapper>
          <SectionTitle>Register Ethereum address</SectionTitle>
        </ComponentWrapper>

        <ComponentScroll>
          <ComponentWrapper noPaddingVertical>
            <Text>
              <h3>Select an ethereum address</h3>
              <p>
                {
                  'On this address you will receive your security tokens. You can also use it to purchase security tokens using Ether, Gemini or Tether.'
                }
              </p>
            </Text>
          </ComponentWrapper>

          {/* <ComponentWrapper noPaddingBottom>
            <FAQ
              items={[
                {
                  title: "Which address should i choose?",
                  content:
                    "Aliquam orci nibh, convallis eu fringilla vel, pharetra non libero. Donec feugiat vehicula sem non sagittis. Fusce vitae purus nulla. Curabitur venenatis augue in augue venenatis placerat non a libero. Quisque facilisis porta libero ac aliquam. Vestibulum ipsum mauris, pretium sed felis et, posuere ultricies purus. Aliquam aliquet arcu a tincidunt mattis. Fusce a vestibulum magna."
                }
              ]}
            />
          </ComponentWrapper> */}

          <ComponentWrapper>
            <SectionTitle>YOUR CURRENT ADDRESS</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper borderTop borderBottom>
            {correct ? (
              <CryptoAddressWrapper
                refresh={refresh ? 'Refreshing' : ''}
                failed={
                  failed
                    ? 'NO DICE. PLEASE CHECK METAMASK IS CONNECTED, THEN HIT REFRESH.'
                    : ''
                }
              >
                <CryptoAddress
                  title="ethereum address"
                  address={address}
                  data={data}
                />
              </CryptoAddressWrapper>
            ) : (
              <CryptoAddressWrapper
                refresh={
                  !correct && !failed
                    ? "We're fetching your registered address from your MetaMask."
                    : ''
                }
                failed={
                  failed
                    ? "Our interface couldn't connect to MetaMask because you need to give permission to do so. Try again?"
                    : ''
                }
              >
                <CryptoAddress
                  title="ethereum address"
                  address={address}
                  data={data}
                />
              </CryptoAddressWrapper>
            )}
          </ComponentWrapper>

          <ComponentWrapper noPaddingBottom>
            <EthInfo center>
              {
                'To use a different MetaMask address, select one from the MetaMask extension on your browser and press the refresh button below.'
              }
            </EthInfo>
          </ComponentWrapper>

          <ComponentWrapper center>
            <RefreshButton onClick={this.onRefreshClick}>
              TRY AGAIN
            </RefreshButton>
          </ComponentWrapper>

          <ComponentWrapper noPaddingTop center>
            <Button
              minWidth="300px"
              onClick={this.onRegisterClick}
              disabled={!correct}
            >
              REGISTER THIS ADDRESS
            </Button>
          </ComponentWrapper>

          <ComponentWrapper noPaddingBottom>
            <SectionTitle>Frequently Asked Questions</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper>
            <FAQ
              items={[
                {
                  title: 'Which address should I choose?',
                  content:
                    "If you're planning to use the address to purchase tokens, choose one with sufficient funds or that you plan to top up. For your security and privacy, it's considered best practice to use more than one address for your crypto investments.",
                },
              ]}
            />
          </ComponentWrapper>
        </ComponentScroll>
      </ModalWrapper>
    )
  }
}

EthAddressMetamask.propTypes = {
  changeStep: PropTypes.func.isRequired,
}

export default EthAddressMetamask
