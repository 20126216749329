import React, { useState, useContext } from 'react'
import Text from 'components/Text/Text.styles'
import { Modal, ModalInner, ModalBack } from 'components/Modal/Modal'
import AgreementItem from 'components/AgreementItem/AgreementItem'
import {
  Button,
  SectionTitle,
  ComponentWrapper,
  ComponentScroll,
  StepController,
  StepControllerProvider,
  StepControllerConsumer,
  AuthContext,
} from '@stokr/components-library'
import { RiskStep } from 'components/RiskQuestionnaire/_styles'
import {
  RiskQuestionnaireProvider,
  RiskQuestionnaireConsumer,
} from 'components/RiskQuestionnaire/RiskQuestionnaireContext'
import { ModalWrapper } from 'components/Modal/Modal.styles'
import { CheckboxContext } from 'context/CheckboxContext'
import parse from 'html-react-parser'

const RiskQuestionnaireFlowPage = ({
  showFlow,
  setShowFlow,
  setShowComplete,
}) => {
  const { updateUser, user } = useContext(AuthContext)
  const { checkCheckboxGroup } = useContext(CheckboxContext)

  const [flowSteps, setFlowSteps] = useState({})

  const updateSteps = (data) => {
    //set flowSteps to false for each step in question set
    //so we can later check whether we checked all the steps
    const steps = {}

    Object.entries(data).map((step) => {
      steps[step[1]._id] = false
      return step
    })

    setFlowSteps(steps)
  }

  const onAgreeChange = (e) => {
    setFlowSteps({ ...flowSteps, [e.target.name]: e.target.checked })
  }

  return (
    showFlow && (
      <RiskQuestionnaireProvider
        updateSteps={updateSteps}
        updateUser={updateUser}
      >
        <RiskQuestionnaireConsumer>
          {(riskQuestionnaire) => {
            const {
              waiting,
              error,
              questionnaire,
              sendQuestionnaire,
              success,
            } = riskQuestionnaire

            const questionArr = []
            let firstQuestion = null
            //go over each question in the questionnaire and add the function to handlethe next click
            if (questionnaire !== null) {
              const entries = Object.entries(questionnaire)
              ;[firstQuestion] = entries
              questionArr.push({
                _id: 'intro',
                getHandleClick: (stepController) => () => {
                  stepController.changeStep('intro', 0)
                },
              })

              const length = entries.length
              for (let i = 0; i < length; i += 1) {
                const question = entries[i][1]
                const nextQuestion = entries[i + 1] && entries[i + 1][1]

                questionArr.push({
                  ...question,
                  getHandleClick: (stepController) => () => {
                    stepController.changeStep(question._id, i + 1)
                  },
                  getHandleNextClick: nextQuestion
                    ? (stepController) => () => {
                        stepController.changeStep(
                          nextQuestion._id,
                          i + 2,
                          i + 1,
                        )
                      }
                    : () => async () => {
                        try {
                          await sendQuestionnaire()
                          await checkCheckboxGroup({
                            userId: user._id,
                            group: 'riskQuestionnaire',
                          })
                          setShowComplete(true)
                          setShowFlow(false)
                        } catch (error) {}
                      },
                  nextButtonText: nextQuestion ? 'Next' : 'Confirm',
                })
              }
            }

            return (
              <>
                <Modal
                  thin
                  fullscreen
                  isOpen={waiting}
                  onClose={() => setShowFlow(false)}
                >
                  <ModalInner noPadding center>
                    <Text center>
                      <h3>Waiting...</h3>
                    </Text>
                  </ModalInner>
                </Modal>

                <Modal
                  thin
                  fullscreen
                  isOpen={error !== null}
                  onClose={() => setShowFlow(false)}
                >
                  <ModalInner noPadding center>
                    <Text>
                      <h3>Something went wrong. Please try again.</h3>
                    </Text>
                  </ModalInner>
                </Modal>

                <Modal
                  thin
                  fullscreen
                  isOpen={
                    !waiting && !success && !error && questionnaire !== null
                  }
                  onClose={() => setShowFlow(false)}
                >
                  <ModalInner noPadding>
                    {questionnaire === null ? null : (
                      <StepControllerProvider>
                        <StepControllerConsumer>
                          {(stepController) => {
                            const prevStepIndex =
                              stepController.activeStepIndex - 1

                            return (
                              <>
                                <ModalBack
                                  onClick={() =>
                                    stepController.changeStep(
                                      questionArr[prevStepIndex]._id,
                                      prevStepIndex,
                                    )
                                  }
                                  hide={prevStepIndex < 0}
                                />
                                <StepController
                                  stepsProgressList={questionArr.map(
                                    (question) => ({
                                      id: question._id,
                                      handleClick:
                                        question.getHandleClick(stepController),
                                    }),
                                  )}
                                  {...stepController}
                                >
                                  <RiskStep id="intro">
                                    <ModalWrapper>
                                      <ComponentScroll fullHeight>
                                        <ComponentWrapper noPaddingBottom>
                                          <Text>
                                            <h3>RISK CONSIDERATIONS</h3>
                                            <p>
                                              “Risk comes from not knowing what
                                              you are doing.” - Warren Buffett
                                            </p>
                                            <p>
                                              We can’t make you instantly wise,
                                              but we can point you to some of
                                              the main risks you will face when
                                              investing via STOKR.
                                            </p>
                                          </Text>
                                        </ComponentWrapper>

                                        <ComponentWrapper>
                                          <Button
                                            minWidth="240px"
                                            onClick={
                                              firstQuestion
                                                ? () => {
                                                    stepController.changeStep(
                                                      firstQuestion[1]._id,
                                                      1,
                                                      0,
                                                    )
                                                  }
                                                : () => {
                                                    setShowComplete(true)
                                                  }
                                            }
                                          >
                                            Start
                                          </Button>
                                        </ComponentWrapper>
                                      </ComponentScroll>
                                    </ModalWrapper>
                                  </RiskStep>

                                  {questionArr
                                    .filter(
                                      (question) => question._id !== 'intro',
                                    )
                                    .map((question) => (
                                      <RiskStep
                                        key={question._id}
                                        id={question._id}
                                      >
                                        <ModalWrapper>
                                          <ComponentScroll fullHeight>
                                            <ComponentWrapper noPaddingBottom>
                                              <SectionTitle>
                                                Risk Questionnaire
                                              </SectionTitle>
                                            </ComponentWrapper>

                                            <ComponentWrapper noPaddingBottom>
                                              <AgreementItem
                                                title={question.title}
                                                text={parse(question.text)}
                                                checkbox={{
                                                  id: `agree-${question._id}`,
                                                  name: question._id,
                                                  text: parse(
                                                    question.agreementText,
                                                  ),
                                                  value: 'agree',
                                                  onChange: onAgreeChange,
                                                  onBlur: () => {},
                                                  checked:
                                                    flowSteps[question._id],
                                                }}
                                                // info={question.info}
                                              />
                                            </ComponentWrapper>

                                            <ComponentWrapper>
                                              <Button
                                                minWidth="240px"
                                                onClick={question.getHandleNextClick(
                                                  stepController,
                                                )}
                                                disabled={
                                                  !flowSteps[question._id]
                                                }
                                              >
                                                {question.nextButtonText}
                                              </Button>
                                            </ComponentWrapper>
                                          </ComponentScroll>
                                        </ModalWrapper>
                                      </RiskStep>
                                    ))}
                                </StepController>
                              </>
                            )
                          }}
                        </StepControllerConsumer>
                      </StepControllerProvider>
                    )}
                  </ModalInner>
                </Modal>

                <Modal
                  thin
                  fullscreen
                  isOpen={success}
                  onClose={() => setShowComplete(true)}
                >
                  <ModalInner noPadding center>
                    <ComponentWrapper noPaddingBottom>
                      <Text center>
                        <h3>Success</h3>
                      </Text>
                    </ComponentWrapper>

                    <ComponentWrapper>
                      <Button
                        minWidth="240px"
                        onClick={() => setShowComplete(true)}
                      >
                        Continue
                      </Button>
                    </ComponentWrapper>
                  </ModalInner>
                </Modal>
              </>
            )
          }}
        </RiskQuestionnaireConsumer>
      </RiskQuestionnaireProvider>
    )
  )
}

export default RiskQuestionnaireFlowPage
