import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Text from 'components/Text/Text.styles'
import {
  Button,
  FAQ,
  SectionTitle,
  ComponentWrapper,
  ComponentScroll,
} from '@stokr/components-library'

import Form, { FormField, FormError } from 'components/Form/Form'
import Input from 'components/Input/Input'

import { ModalWrapper } from 'components/Modal/Modal.styles'

class EthAddressName extends PureComponent {
  initialValues = {
    name: '',
  }

  validationSchema = Yup.object().shape({
    name: Yup.string().required('Address name is required'),
  })

  onSubmit = () => {
    const { changeStep } = this.props

    changeStep()
  }

  render() {
    const { error, isUpdatingUser } = this.props

    return (
      <ModalWrapper>
        <ComponentWrapper>
          <SectionTitle>Register Ethereum address</SectionTitle>
        </ComponentWrapper>

        <ComponentScroll>
          <ComponentWrapper noPaddingVertical>
            <Text>
              <h3>Name your ethereum address</h3>
              <p>
                {
                  "Give your address a name that reflects what it will store. Remember Satoshi's recommendation to spread your crypto investments over different addresses. "
                }
              </p>
            </Text>
          </ComponentWrapper>

          <ComponentWrapper noPaddingBottom>
            <FAQ
              items={[
                {
                  title: 'Why should I name my Ethereum address?',
                  content:
                    "If you're following best practice and spreading your crypto investments over different addresses, a name makes it easier to manage your portfolio.",
                },
              ]}
            />
          </ComponentWrapper>

          <ComponentWrapper>
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                setFieldValue,
                setFieldTouched,
              }) => {
                const onChangeWithTouch = e => {
                  const { setNameToRegister } = this.props
                  const field = e.target
                  setFieldValue(field.name, field.value, false)
                  setFieldTouched(field.name)

                  setNameToRegister(field.value)
                }

                const submitDisabled =
                  !touched.name || !!errors.name || isUpdatingUser

                return (
                  <Form>
                    <ComponentWrapper noPadding>
                      <FormField>
                        <Input
                          id="register-eth-name"
                          name="name"
                          label="Address name"
                          value={values.name}
                          onChange={onChangeWithTouch}
                          onBlur={handleBlur}
                          error={!!errors.name}
                          touched={!!touched.name}
                        />
                        <FormError show={errors.name && touched.name}>
                          {errors.name}
                        </FormError>
                      </FormField>
                    </ComponentWrapper>

                    <ComponentWrapper
                      noPaddingHorizontal
                      noPaddingBottom
                      center
                    >
                      <Button
                        type="submit"
                        minWidth="240px"
                        disabled={submitDisabled}
                      >
                        Use this name
                      </Button>
                      <FormError withTopSpacing show={error}>
                        {error}
                      </FormError>
                      `
                    </ComponentWrapper>
                  </Form>
                )
              }}
            </Formik>
          </ComponentWrapper>

          <ComponentWrapper noPaddingBottom>
            <SectionTitle>Frequently Asked Questions</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper>
            <FAQ
              items={[
                {
                  title: 'Why should I name my Ethereum address?',
                  content:
                    "If you're following best practice and spreading your crypto investments over different addresses, a name makes it easier to manage your portfolio.",
                },
              ]}
            />
          </ComponentWrapper>
        </ComponentScroll>
      </ModalWrapper>
    )
  }
}

EthAddressName.propTypes = {
  changeStep: PropTypes.func.isRequired,
}

export default EthAddressName
