import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Text from 'components/Text/Text.styles'
import {
  Checkbox,
  CheckboxPropTypes,
  InfoIcon,
  ComponentWrapper,
} from '@stokr/components-library'

import { Container, InfoBox } from './AgreementItem.styles'

const AgreementItem = ({ title, text, checkbox, info }) => (
  <Container>
    <Text>
      {title && <h3>{title}</h3>}
      <p>{text}</p>
    </Text>
    {checkbox && (
      <ComponentWrapper noPaddingHorizontal noPaddingBottom>
        <Checkbox
          id={checkbox.id}
          name={checkbox.name || checkbox.id}
          text={
            <Fragment>
              {checkbox.text}
              {info && (
                <InfoIcon position="bottom" html={<InfoBox>{info}</InfoBox>} />
              )}
            </Fragment>
          }
          value={checkbox.value}
          checked={checkbox.checked}
          onChange={checkbox.onChange}
          onBlur={checkbox.onBlur}
        />
      </ComponentWrapper>
    )}
  </Container>
)

AgreementItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  info: PropTypes.string,
  checkbox: PropTypes.shape(CheckboxPropTypes).isRequired,
}

AgreementItem.defaultProps = {
  title: '',
  info: '',
}

export default AgreementItem
