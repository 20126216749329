import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-collapse'

import Text from 'components/Text/Text.styles'
import {
  Button,
  SectionTitle,
  ComponentWrapper,
  ComponentScroll,
  Process,
} from '@stokr/components-library'

import { ModalWrapper } from 'components/Modal/Modal.styles'

import { isLedgerAvailable } from 'api/StokrWeb3'

const processState = {
  DEFAULT: '',
  WAITING: 'waiting',
  SUCCESS: 'success',
  FAILED: 'failed',
}

class EthConnectLedger extends PureComponent {
  state = {
    showRetry: false,
    showContinue: false,
    firstProcess: processState.WAITING,
    secondProcess: processState.DEFAULT,
  }

  async detectLedger() {
    if (await isLedgerAvailable()) {
      this.setState({
        firstProcess: processState.SUCCESS,
        secondProcess: processState.SUCCESS,
        showRetry: false,
        showContinue: true,
      })
    } else {
      this.setState({
        firstProcess: processState.FAILED,
        secondProcess: processState.FAILED,
        showRetry: true,
        showContinue: false,
      })
    }
  }

  async componentDidMount() {
    await this.detectLedger()
  }

  componentDidUpdate(prevState) {
    const { firstProcess: prevFirstProcess, secondProcess: prevSecondProcess } =
      prevState

    const { firstProcess, secondProcess } = this.state

    if (
      firstProcess !== prevFirstProcess ||
      secondProcess !== prevSecondProcess
    ) {
      clearTimeout(this.timeout)

      if (
        firstProcess === processState.SUCCESS &&
        secondProcess === processState.WAITING
      ) {
        this.setStateDelayed(
          {
            showContinue: true,
            secondProcess: processState.SUCCESS,
          },
          2000,
        )
      }
    }
  }

  onRetryClick = () => {
    this.setState({
      showRetry: false,
      firstProcess: processState.WAITING,
      secondProcess: processState.DEFAULT,
    })
    this.detectLedger()
  }

  onContinueClick = () => {
    const { changeStep } = this.props

    changeStep()
  }

  setStateDelayed = (newState, time) => {
    this.timeout = setTimeout(() => {
      this.setState(newState)
    }, time)
  }

  getProcessProps = (stateName) => (stateName ? { [stateName]: true } : {})

  render() {
    const { showRetry, showContinue, firstProcess } = this.state

    return (
      <ModalWrapper>
        <ComponentWrapper>
          <SectionTitle>Register Ethereum address</SectionTitle>
        </ComponentWrapper>

        <ComponentScroll>
          <ComponentWrapper noPaddingVertical>
            <Text>
              <h3>PLUG IN YOUR DEVICE</h3>
              <p>
                {
                  "Psst... using a different hardware wallet? No dice. At the moment, we only support Ledger, but we're working on it."
                }
              </p>
            </Text>
          </ComponentWrapper>

          <ComponentWrapper noPaddingBottom>
            <Process {...this.getProcessProps(firstProcess)}>
              1. PLUG IN YOUR DEVICE
              <br />
              <p>
                Grab that USB cable and get your computer and cryptovault
                connected.
              </p>
              2. ENTER YOUR PIN
              <br />
              <p>
                Use the buttons on your device to enter and confirm your pin.
              </p>
              3. SELECT ETHEREUM APP
              <br />
              <p>
                Navigate to the Ethereum app on your Ledger and select it by
                pressing both buttons at once.
              </p>
            </Process>
          </ComponentWrapper>

          {/*
          <ComponentWrapper>
            <Process {...this.getProcessProps(secondProcess)}>
              Open the ethereum app on your ledger
            </Process>
          </ComponentWrapper>
          */}

          <Collapse isOpened={showRetry}>
            <ComponentWrapper center>
              <Button minWidth="240px" onClick={this.onRetryClick}>
                Retry
              </Button>
            </ComponentWrapper>
          </Collapse>

          <Collapse isOpened={showContinue}>
            <ComponentWrapper center>
              <Button minWidth="240px" onClick={this.onContinueClick}>
                Next
              </Button>
            </ComponentWrapper>
          </Collapse>

          {/*<ComponentWrapper noPaddingBottom>
            <SectionTitle>Frequently Asked Questions</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper>
            <FAQ
              items={[
                {
                  title: 'Can I use another hardware wallet?',
                  content:
                    "Psst... using a different hardware wallet? No dice. At the moment, we only support Ledger, but we're working on it.",
                },
              ]}
            />
          </ComponentWrapper> */}
        </ComponentScroll>
      </ModalWrapper>
    )
  }
}

EthConnectLedger.propTypes = {
  changeStep: PropTypes.func.isRequired,
}

export default EthConnectLedger
