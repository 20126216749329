import React, { useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import RegisterAlgorand from './register-algorand'
import { showUnsupported } from 'api/StokrWeb3'
//import { AlgoAddressFlowPage, AuthProvider } from '@stokr/components-library'
import RegisterAlgorandPendingPage from './pending'
import AlgoAddressFlowPage from './flow'

const RegisterAlgorandPage = () => {
  const [isUnsupported, setIsUnsupported] = useState(false)
  const [showFlow, setShowFlow] = useState(false)
  const [showPending, setShowPending] = useState(false)

  const renderPages = () => {
    // if (isUnsupported) {
    //   return <RegEthUnsupported />
    // }

    if (showPending) {
      return <RegisterAlgorandPendingPage />
    }

    return <RegisterAlgorand setShowFlow={setShowFlow} />
  }

  useEffect(() => {
    if (showUnsupported()) {
      setIsUnsupported(true)
    }
  }, [])

  return (
    <Layout signupFlow progress>
      <SEO title="Algorand Address" />
      {renderPages()}
      {/* <AuthProvider> */}
      <AlgoAddressFlowPage
        showFlow={showFlow}
        setShowFlow={setShowFlow}
        setShowPending={setShowPending}
        withSuccessPage
        //project={{ title: 'Algorand Test', primaryAssetId: '20918100' }}
      />
      {/* </AuthProvider> */}
    </Layout>
  )
}

export default RegisterAlgorandPage
