import React, { useContext, useEffect, useState } from 'react'
import {
  ComponentScroll,
  Row,
  Column,
  ComponentWrapper,
} from '@stokr/components-library'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import { Modal, ModalInner } from 'components/Modal/Modal'
import Text from 'components/Text/Text.styles'
import { Wrapper, Header, Body, HeaderTitle } from './_styles'
import { useLocation } from 'react-router-dom'
import { CheckboxContext } from 'context/CheckboxContext'
import parse from 'html-react-parser'
import theme from 'styles/theme'
import styled from 'styled-components'

const StyledPrivacyText = styled.div`
  && {
    width: 100%;

    p,
    div {
      color: ${theme.cBlack};
      font-size: 16px;
      line-height: 1.5;
      font-weight: 300;
      margin: 0;
      padding-bottom: 10px;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.6px;
    }
    a {
      text-decoration: none;
      color: ${theme.cBlack};
      font-weight: 300;
    }

    h5 {
      font-family: 'Open Sans', sans-serif;
      color: ${theme.cBlack};
      font-weight: 800;
      font-size: 16px;
      line-height: 1.27em;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      margin: calc(2rem - 0.14286em) 0 1rem;
    }

    h4 {
      font-size: 17.2px !important;
      font-weight: 700 !important;
      line-height: 1.28571em;
      letter-spacing: normal;
      position: relative;
      margin-top: 29px;
      margin-bottom: 16px;
    }
  }
`

const Terms = (props) => {
  const { getCheckbox } = useContext(CheckboxContext)
  const [termsCheckbox, settermsCheckbox] = useState({
    agreementText:
      "By creating this account, I agree with STOKR's Terms of Use",
    _id: 'terms',
    text: 'Loading text...',
  })
  const location = useLocation()

  useEffect(() => {
    if (location.state?.termsData) {
      settermsCheckbox(location.state?.termsData)
    } else {
      fecthCheckbox()
    }
  }, [])

  const fecthCheckbox = async () => {
    try {
      const result = await getCheckbox({ labels: ['signUpTerms'] })
      if (result?.signUpTerms) {
        settermsCheckbox(result.signUpTerms)
      }
    } catch (error) {
      console.log('🚀 ~ file: welcome.js:56 ~ error:', error)
    }
  }

  return (
    <Layout signupFlow noFooter>
      <>
        <SEO title="Terms of Use" />
        <Modal isOpen onClose={() => window.history.back()}>
          <Row>
            <Column>
              <ModalInner noPadding isMobile>
                {/* <ModalBack
                      onClick={onModalSwitch}
                    /> */}
                <Wrapper>
                  <Header>
                    <HeaderTitle>TERMS OF USE</HeaderTitle>
                  </Header>
                  <Body noHorizontalPadding isTerms>
                    <ComponentScroll fullHeight>
                      <ComponentWrapper noPaddingVertical>
                        <StyledPrivacyText>
                          {parse(termsCheckbox.text)}
                        </StyledPrivacyText>
                      </ComponentWrapper>
                    </ComponentScroll>
                  </Body>
                </Wrapper>
              </ModalInner>
            </Column>
          </Row>
        </Modal>
      </>
    </Layout>
  )
}

export default Terms
