import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import fetchData from 'api/fetchData'

export const RiskQuestionnaireContext = React.createContext()

export const RiskQuestionnaireProvider = ({
  children,
  updateSteps,
  updateUser,
}) => {
  const [waiting, setWaiting] = useState(true)
  const [error, setError] = useState(null)
  const [questionnaire, setQuestionnaire] = useState(null)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const result = await fetchData('compliance/get-checkbox-group', {
        group: 'riskQuestionnaire',
      })

      onGetDataSuccess(result)
    } catch (error) {
      console.log(`Error fetching risk questionnaire: ${error}`)
      onGetDataError(error.message)
    }
  }

  const sendData = async (questionnaire) => {
    setWaiting(true)
    setError(null)
    setSuccess(false)

    try {
      await updateUser({ is_risk_questionnaire_finished: true })
      setSuccess(true)
    } catch (error) {
      console.log(`Error setting user risk questionnaire status: ${error}`)
      onSendDataError('Something went wrong. Please try again.')
    } finally {
      setWaiting(false)
    }
  }

  const onGetDataSuccess = (data) => {
    updateSteps(data)

    setWaiting(false)
    setQuestionnaire(data)
  }

  const onGetDataError = (error) => {
    setWaiting(false)
    setError(error)
  }

  const onSendDataSuccess = () => {
    setWaiting(false)
    setSuccess(true)
  }

  const onSendDataError = (error) => {
    setWaiting(false)
    setError(error)
  }

  const contextValue = {
    waiting,
    error,
    questionnaire,
    sendQuestionnaire: () => sendData(questionnaire),
    success,
  }

  return (
    <RiskQuestionnaireContext.Provider value={contextValue}>
      {children}
    </RiskQuestionnaireContext.Provider>
  )
}

RiskQuestionnaireProvider.propTypes = {
  children: PropTypes.node.isRequired,
  updateSteps: PropTypes.func.isRequired,
}

export const RiskQuestionnaireConsumer = RiskQuestionnaireContext.Consumer
