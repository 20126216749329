import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { EthInfo } from 'components/RegisterEthereum'
import Text from 'components/Text/Text.styles'
import {
  Button,
  ComponentWrapper,
  RefreshButton,
  FAQ,
  SectionTitle,
  ComponentScroll,
  CryptoAddressWrapper,
  CryptoAddress,
} from '@stokr/components-library'

import { ModalWrapper } from 'components/Modal/Modal.styles'
import {
  isAlgoSignerInstalled,
  getAlgoSignerAddress,
  getMyAlgoWalletAddress,
} from 'api/StokrWeb3'
import fetchData from 'api/fetchData'

const AlgoAdressSelect = (props) => {
  const [correct, setcorrect] = useState(false)
  const [refresh, setrefresh] = useState(false)
  const [failed, setfailed] = useState(false)
  const [selectedAdress, setselectedAdress] = useState({})
  const [address, setaddress] = useState([
    {
      address: '0x0000000000000000000000000000000000000000',
      balance: 0,
    },
  ])

  useEffect(() => {
    async function initWallet() {
      if (props.wallet === 'algosigner') {
        await initAlgoSigner()
      }
      if (props.wallet === 'myalgo') {
        await initMyAlgoWallet()
      }
    }
    initWallet()
  }, [])

  useEffect(() => {
    if (refresh) {
      setStateDelayed(false, 2000)
    }
  }, [refresh])

  useEffect(() => {
    if (props.verifiedAddress) {
      props.changeStep()
    }
  }, [props.verifiedAddress])

  //get balance for each user address
  const getBalanceData = async (addresses) => {
    let addressArr = []
    addresses.forEach((address) => {
      addressArr.push(address.address)
    })

    const data = await fetchData('wallets/algoBalances', {
      accounts: addressArr,
    })
    const algoPrice = await fetchData('investment/algoprice')

    data &&
      algoPrice &&
      data.forEach((account, index) => {
        addresses[index].balance = account.balance
        addresses[index].currentAlgoPrice = algoPrice.EUR // -- in euros
        addresses[index].eqUnit = '€'
      })

    checkforExistingAddress(addresses)
  }

  //check if user has already registered some address
  //add the address title if exist
  const checkforExistingAddress = (addresses) => {
    addresses.forEach((address) => {
      props.userAddresses &&
        props.userAddresses.forEach((userAdd) => {
          if (address.address === userAdd.address) {
            address.title = userAdd.name

            //if address found -- check if it's already opted in
            userAdd.assetId &&
              userAdd.assetId.forEach((asset) => {
                if (asset === Number(props.projectAssetId)) {
                  address.optedIn = true
                }
              })
          }
        })
    })
  }

  const onAddressChange = (account) => {
    setselectedAdress(account)
    props.setRegisteredAddress(account.title ? true : false)
  }

  const initAlgoSigner = async () => {
    if (isAlgoSignerInstalled()) {
      try {
        const address = await getAlgoSignerAddress('TestNet')
        console.log('address: ', address)
        if (address) {
          await getBalanceData(address)

          setfailed(false)
          setcorrect(true)
          setaddress(address)
        }
      } catch (error) {
        console.log('eroor: ', error)

        setfailed(true)
        setcorrect(false)
      }
    }
  }

  const getAdressArr = (addressArr) => {
    let newArr = []
    addressArr &&
      addressArr.forEach((element) => {
        newArr.push({ address: element })
      })
    return newArr
  }

  const initMyAlgoWallet = async () => {
    try {
      const address = await getMyAlgoWalletAddress()
      if (address && !address.includes('Error')) {
        let addressArr = getAdressArr(address)
        await getBalanceData(addressArr)

        setaddress(addressArr)
        setfailed(false)
        setcorrect(true)
      }
    } catch (error) {
      console.log('eroor: ', error)
      setfailed(true)
      setcorrect(false)
    }
  }

  const setStateDelayed = (newState, time) => {
    setTimeout(() => {
      setrefresh(newState)
    }, time)
  }

  const onRefreshClick = async () => {
    setrefresh(true)
    props.wallet === 'algosigner'
      ? await initAlgoSigner()
      : await initMyAlgoWallet()
  }

  return (
    <ModalWrapper>
      <ComponentWrapper>
        <SectionTitle>Register Algorand address</SectionTitle>
      </ComponentWrapper>

      <ComponentScroll>
        <ComponentWrapper noPaddingVertical>
          <Text>
            <h3>Select an Algorand address</h3>

            <p>
              {`In order for the tokens to go to the right address, you need to choose the opted-in address for the tokens to be properly assigned to you. `}
            </p>
          </Text>
        </ComponentWrapper>

        <ComponentWrapper>
          <SectionTitle>YOUR CURRENT ADDRESS</SectionTitle>
        </ComponentWrapper>

        <ComponentWrapper>
          {correct ? (
            <CryptoAddressWrapper
              refresh={refresh ? 'Refreshing' : ''}
              failed={
                failed
                  ? `NO DICE. PLEASE CHECK ${
                      props.wallet === 'algosigner'
                        ? 'Algosigner'
                        : 'My Algo Wallet'
                    } IS CONNECTED, THEN HIT REFRESH.`
                  : ''
              }
            >
              {address.map((account, i) => (
                <CryptoAddress
                  key={account + i}
                  title={account.title || 'Not yet registered'}
                  address={account.address}
                  data={{
                    value: account.balance.toFixed(4),
                    unit: 'ALGO',
                    eqValue: (
                      account.balance * account.currentAlgoPrice
                    ).toFixed(2),
                    eqUnit: '€',
                  }}
                  wrapped
                  borderTop
                  borderBottom
                  sideOptions={
                    <Button
                      secondary={
                        selectedAdress.address === account.address
                          ? false
                          : true
                      }
                      disabled={account.optedIn}
                      style={{ minWidth: 'max-content' }}
                      onClick={() => {
                        props.setAddressToRegister(account.address)
                        props.handleOptIn(
                          account.address,
                          account.title ? true : false,
                        )
                        onAddressChange(account)
                      }}
                    >
                      Select
                    </Button>
                  }
                />
              ))}
            </CryptoAddressWrapper>
          ) : (
            <CryptoAddressWrapper
              refresh={
                !correct && !failed
                  ? `We're fetching your registered address from your ${
                      props.wallet === 'algosigner'
                        ? 'Algosigner'
                        : 'My Algo Wallet'
                    }.`
                  : ''
              }
              failed={
                failed
                  ? `Our interface couldn't connect to ${
                      props.wallet === 'algosigner'
                        ? 'Algosigner'
                        : 'My Algo Wallet'
                    }  because you need to give permission to do so. Try again?`
                  : ''
              }
            >
              {address.map((account, i) => (
                <CryptoAddress
                  key={account + i}
                  title={account.title || 'Algorand address'}
                  address={account.address}
                  data={{
                    value: account.balance.toFixed(4),
                    unit: 'ALGO',
                    eqValue: (
                      account.balance * account.currentAlgoPrice
                    ).toFixed(2),
                    eqUnit: '€',
                  }}
                  wrapped
                  borderTop
                  borderBottom
                  sideOptions={
                    <Button
                      secondary={
                        selectedAdress.address === account.address
                          ? false
                          : true
                      }
                      style={{ minWidth: 'max-content' }}
                      onClick={() => {
                        props.setAddressToRegister(account.address)
                        props.handleOptIn(
                          account.address,
                          account.title ? true : false,
                        )
                        onAddressChange(account)
                      }}
                    >
                      Select
                    </Button>
                  }
                />
              ))}
            </CryptoAddressWrapper>
          )}
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <EthInfo center>
            {`To use a different ${
              props.wallet === 'algosigner' ? 'Algosigner' : 'My Algo Wallet'
            } address, select one from the ${
              props.wallet === 'algosigner' ? 'Algosigner' : 'My Algo Wallet'
            } extension on your browser and press the "TRY AGAIN" button below.`}
          </EthInfo>
        </ComponentWrapper>

        <ComponentWrapper center>
          <RefreshButton onClick={onRefreshClick}>TRY AGAIN</RefreshButton>
        </ComponentWrapper>

        {/* <ComponentWrapper noPaddingTop center>
          <Button
            minWidth="300px"
            onClick={onRegisterClick}
            disabled={
              !correct ||
              selectedAdress.address === undefined ||
              !props.verifiedAddress
            }
          >
            REGISTER THIS ADDRESS
          </Button>
        </ComponentWrapper> */}

        <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>

        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: 'Which address should I choose?',
                content:
                  "If you're planning to use the address to purchase tokens, choose one with sufficient funds or that you plan to top up. For your security and privacy, it's considered best practice to use more than one address for your crypto investments.",
              },
            ]}
          />
        </ComponentWrapper>
      </ComponentScroll>
    </ModalWrapper>
  )
}

AlgoAdressSelect.propTypes = {
  changeStep: PropTypes.func.isRequired,
}

export default AlgoAdressSelect
