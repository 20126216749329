import styled from 'styled-components'
import { Collapse } from 'react-collapse'

export const FaqItems = styled.div`
  position: relative;
`

export const FaqItem = styled.div`
  & + & {
    margin-top: 30px;
  }
`

export const FaqTitle = styled.button`
  display: block;
  width: 100%;
  position: relative;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${(props) => props.theme.cBlack};
  padding: 0;
  margin: 0;
  outline: 0;
  border: none;
  padding-right: 30px;
  text-align: left;
  transition: color 0.2s;

  &:hover {
    color: inherit;
  }

  ${(props) =>
    props.isActive &&
    `
    ${FaqDropdownIcon} {
      transform: rotate(180deg);
    }
  `}
`

export const FaqDropdownIcon = styled.i.attrs({
  className: 'ion ion-ios-arrow-down',
})`
  width: 14px;
  height: 20px;
  font-size: 20px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  transform: rotate(0);
  transition: 0.3s transform;
`

export const FaqContent = styled(Collapse)`
  opacity: 0;
  transition: opacity 0.2s;

  ${(props) =>
    props.isOpened &&
    `
    opacity: 1;
  `}
`

export const FaqText = styled.div`
  padding: 0;
  padding-top: 30px;
  padding-bottom: 10px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
`
