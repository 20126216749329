import React from 'react'
import { useCustomNav } from 'utils/customNavigate'
import {
  Background,
  Button,
  TextButton,
  ComponentWrapper,
  Row,
  Column,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import backgroundImage from 'static/images/covers/rocket.png'
import ToDoTask from 'components/ToDoList/ToDoListTask'
import { ToDoTaskState } from 'components/ToDoList/ToDoListTask'
import { iconsMap } from 'components/Icon/Icon.style'
import redirectUrl from 'utils/redirect-url'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import { DisplayOnBreakpoint } from 'styles/rwd'
import { RedirectSpacer } from './verify-identity'

const VerifyIdentityComplete = ({ isKYCComplete }) => {
  const navigator = useCustomNav()
  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background src={backgroundImage} mobileRatio="16:9" />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        {!isKYCComplete && !redirectUrl ? (
          <ComponentWrapper noPaddingVertical>
            <TextButton
              onClick={() => navigator.navigate('/country-of-residence')}
            >
              Back to Country of residence
            </TextButton>
          </ComponentWrapper>
        ) : (
          <RedirectSpacer />
        )}

        <ComponentWrapper>
          <Text>
            <h3>IDENTITY VERIFICATION</h3>
            <p>Thank you.</p>
          </Text>
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <ToDoTask
            title="Verify Identity"
            message="Your ID is verified."
            state={ToDoTaskState.APPROVED}
            icon={iconsMap.check}
          />

          <ToDoTask
            title="Invest in Euro"
            message="Your document is verified."
            state={ToDoTaskState.APPROVED}
            icon={iconsMap.check}
          />
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <Button
            onClick={() => {
              if (redirectUrl) {
                deleteRedirectCookieAndNavigate()
              } else {
                navigator.navigate('/taxid')
              }
            }}
          >
            Continue
          </Button>
        </ComponentWrapper>
      </Column>
    </Row>
  )
}

export default VerifyIdentityComplete
