import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Text from 'components/Text/Text.styles'
import {
  Button,
  SectionTitle,
  ComponentWrapper,
  ComponentScroll,
} from '@stokr/components-library'

import { ModalWrapper } from 'components/Modal/Modal.styles'

const AlgoSuccess = ({ onClick }) => {
  return (
    <ModalWrapper>
      <ComponentWrapper>
        <SectionTitle>Register Algorand address</SectionTitle>
      </ComponentWrapper>
      <ComponentScroll>
        <ComponentWrapper noPaddingVertical>
          <Text>
            <h3>YOUR ALGO ADDRESS IS BEING WHITELISTED</h3>
            <p>
              All Algorand addresses are checked to prevent money laundering on
              STOKR. We'll let you know as soon as yours is on the whitelist.
            </p>
          </Text>
        </ComponentWrapper>

        <ComponentWrapper noPaddingHorizontal center>
          <Button
            minWidth="240px"
            onClick={() => {
              // deleteRedirectCookieAndNavigate()
              onClick()
            }}
          >
            Continue
          </Button>
        </ComponentWrapper>

        {/* <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>
        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: 'What is a crypto wallet?',
                content:
                  'A crypto wallet is a hardware device or browser interface to manage and protect your private key and as a result the transactions you are making on the blockchain.',
              },
              {
                title: 'Which Algorand wallet should I use?',
                content:
                  'Both wallets perform the exact same function and both are quite easy to download and use. The key difference is that MyAlgo is a more popular choice amongst Algorand holders for its range of functionality. Algosigner might be a good choice if you are just looking for something basic and hold your assets obtained from the STOKR platform.',
              },
            ]}
          />
        </ComponentWrapper> */}
      </ComponentScroll>
    </ModalWrapper>
  )
}

AlgoSuccess.propTypes = {
  setWallet: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
}

export default AlgoSuccess
