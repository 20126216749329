import styled from 'styled-components'

export const Container = styled.div`
  display: block;
`

export const CheckboxWrap = styled.div`
  padding: 6px 0;
`

export const InfoBox = styled.div`
  max-width: 240px;
  text-align: left;
`
