import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Text from 'components/Text/Text.styles'
import {
  Button,
  FAQ,
  SectionTitle,
  ComponentWrapper,
  ComponentScroll,
  DoubleButtons,
} from '@stokr/components-library'

import { ModalWrapper } from 'components/Modal/Modal.styles'
//import { ButtonContainer } from 'components/ButtonContainer/ButtonContainer.styles'

const SelectProject = ({ changeStep, projects, setselectedProject }) => {
  return (
    <ModalWrapper>
      <ComponentWrapper>
        <SectionTitle>Register Algorand address</SectionTitle>
      </ComponentWrapper>

      <ComponentScroll>
        <ComponentWrapper noPaddingVertical>
          <Text>
            <h3>Select a project</h3>
            <p>
              {
                'In order to register an algorand address, you need to select a project first. After this you will opt-in your address so you can start investing in algo projects immediately.'
              }
            </p>
          </Text>
        </ComponentWrapper>

        <ComponentWrapper>
          <DoubleButtons column>
            {projects.length % 2 === 0
              ? projects.map((project, i) => (
                  <>
                    <img src={project.logourl} alt="project icon" height="50" />
                    <Button
                      // minWidth="240px"
                      //secondary={}
                      onClick={() => {
                        setselectedProject(project)

                        changeStep()
                      }}
                      fluid
                    >
                      {project.title}
                    </Button>
                  </>
                ))
              : projects.map((project, i) => (
                  <>
                    <>
                      <img
                        src={project.logourl}
                        alt="project icon"
                        height="50"
                      />
                      <Button
                        minWidth="240px !important"
                        //secondary={}
                        onClick={() => {
                          setselectedProject(project)
                          changeStep()
                        }}
                        fluid
                      >
                        {project.title}
                      </Button>
                    </>
                  </>
                ))}
          </DoubleButtons>
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>
        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: 'What is a crypto wallet?',
                content:
                  'A crypto wallet is a hardware device or browser interface to manage and protect your private key and as a result the transactions you are making on the blockchain.',
              },
              {
                title: 'Which Algorand wallet should I use?',
                content:
                  'Both wallets perform the exact same function and both are quite easy to download and use. The key difference is that MyAlgo is a more popular choice amongst Algorand holders for its range of functionality. Algosigner might be a good choice if you are just looking for something basic and hold your assets obtained from the STOKR platform.',
              },
            ]}
          />
        </ComponentWrapper>
      </ComponentScroll>
    </ModalWrapper>
  )
}

SelectProject.propTypes = {
  setWallet: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
}

export default SelectProject
