import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Text from 'components/Text/Text.styles'
import {
  Button,
  FAQ,
  SectionTitle,
  ComponentWrapper,
  ComponentScroll,
  DoubleButtons,
} from '@stokr/components-library'

import { ModalWrapper } from 'components/Modal/Modal.styles'
import { supportsU2F, isAlgoSignerInstalled } from 'api/StokrWeb3'
import { Tooltip } from 'react-tippy'

const AlgoIntro = ({ data, setWallet, changeStep }) => {
  const [showRefreshButton, setShowRefreshButton] = useState(false)

  return (
    <ModalWrapper>
      <ComponentWrapper>
        <SectionTitle>Register Algorand address</SectionTitle>
      </ComponentWrapper>

      <ComponentScroll>
        <ComponentWrapper noPaddingVertical>
          <Text>
            <h3>Choose your wallet</h3>
            <p>
              {
                'Please choose the wallet you either already have or would like to signup with.'
              }
            </p>
            <p>
              {
                'Before chosing your wallet please make sure you are using the right browser and have the right plug-ins installed.'
              }
            </p>
          </Text>
        </ComponentWrapper>

        <ComponentWrapper>
          <DoubleButtons>
            <Button
              onClick={() => {
                setWallet('myalgo')
                changeStep()
              }}
              // minWidth="240px"
              fluid
            >
              My Algo Wallet
            </Button>
            {showRefreshButton ? (
              <Button
                onClick={() => {
                  setWallet('algosigner')

                  changeStep()
                }}
                fluid
              >
                Refresh
              </Button>
            ) : !supportsU2F() ? (
              <>
                <Tooltip
                  title="A chrome-based browser is required to connect with an Algosigner wallet."
                  position={'top'}
                  theme="light"
                  arrow
                  duration={200}
                  style={{ display: 'inline-flex', width: '50%' }}
                >
                  <Button disabled={!supportsU2F()}>{'AlgoSigner'}</Button>
                </Tooltip>
              </>
            ) : (
              <Button
                onClick={() => {
                  if (isAlgoSignerInstalled()) {
                    setWallet('algosigner')
                    changeStep()
                  } else {
                    window.open(
                      'https://www.purestake.com/technology/algosigner/',
                      '_blank',
                    )
                    setShowRefreshButton(true)
                  }
                }}
                disabled={!supportsU2F()}
                fluid
              >
                {isAlgoSignerInstalled() ? 'AlgoSigner' : 'Get AlgoSigner'}
              </Button>
            )}
          </DoubleButtons>
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>
        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: 'What is a crypto wallet?',
                content:
                  'A crypto wallet is a hardware device or browser interface to manage and protect your private key and as a result the transactions you are making on the blockchain.',
              },
              {
                title: 'Which Algorand wallet should I use?',
                content:
                  'Both wallets perform the exact same function and both are quite easy to download and use. The key difference is that MyAlgo is a more popular choice amongst Algorand holders for its range of functionality. Algosigner might be a good choice if you are just looking for something basic and hold your assets obtained from the STOKR platform.',
              },
            ]}
          />
        </ComponentWrapper>
      </ComponentScroll>
    </ModalWrapper>
  )
}

AlgoIntro.propTypes = {
  setWallet: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
}

export default AlgoIntro
