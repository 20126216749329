import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as scrollToElement from 'scroll-to-element'
import Text from 'components/Text/Text.styles'
import {
  FaqItems,
  FaqItem,
  FaqTitle,
  FaqContent,
  FaqDropdownIcon,
} from './RegisterLiquidSteps.styles'
import { ComponentWrapper, SpanButton } from '@stokr/components-library'
import NavGreen from './assets/nav_green.svg'
import SideSwap from './assets/sideswap_icon.png'
import Video from 'components/video'
import registerLiquidVideo from './assets/video.mp4'
import rwd from 'styles/rwd'

const LiquidDescription = styled.div`
  li {
    font-size: 16px !important;
    line-height: 1.5em !important;
    padding-bottom: 10px;

    ${rwd.XLarge`
    font-size: 20px !important;
  `}
  }

  img {
    vertical-align: middle;
    padding-bottom: 2px;
  }
`

const WalletTitle = styled.div`
  display: flex;
  img {
    margin-right: 10px;
  }
`

class RegisterLiquidSteps extends PureComponent {
  state = {
    activeIndex: 0,
    isVideoOpen: false,
  }

  onTitleClick = (e, index) => {
    e.persist()
    const { activeIndex } = this.state
    const { noScroll } = this.props
    this.setState(
      {
        activeIndex: activeIndex === index ? null : index,
      },
      () => {
        if (!noScroll) {
          if (activeIndex === index) {
            return
          }
          scrollToElement(e.target.parentElement, {
            duration: 300,
            offset: 20,
          })
        }
      },
    )
  }

  render() {
    //const { items } = this.props
    const { activeIndex } = this.state

    const items = [
      {
        title: (
          <WalletTitle>
            <img src={SideSwap} height="25" alt="sideswap_wallet" /> SideSwap
            Wallet (default)
          </WalletTitle>
        ),
        content: (
          <LiquidDescription>
            <ul>
              <li>
                Download the{' '}
                <img src={SideSwap} height="25" alt="green_wallet" />{' '}
                <a
                  href="https://sideswap.io/downloads/"
                  target="_blank"
                  rel="noreferrer"
                >
                  SideSwap Wallet
                </a>{' '}
                app and create a new wallet.
              </li>

              <li>
                Copy the liquid address which is called AMP ID starting with the
                letters 'GA'.
              </li>

              <li>
                For instructions on using the mobile app, please click this{' '}
                <a
                  href="https://stokr.zendesk.com/hc/en-us/articles/9432155491997"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
                .
              </li>
            </ul>
          </LiquidDescription>
        ),
      },
      {
        title: (
          <WalletTitle>
            <img src={NavGreen} height="25" alt="green_wallet" /> Green: Bitcoin
            Wallet (alternative option)
          </WalletTitle>
        ),
        content: (
          <>
            <LiquidDescription>
              <ul>
                <li>
                  Download the{' '}
                  <img src={NavGreen} height="25" alt="green_wallet" />{' '}
                  <a
                    href="https://blockstream.com/green/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Green: Bitcoin Wallet
                  </a>{' '}
                  app.{' '}
                </li>
                <li>
                  It is Important to choose under the{' '}
                  <strong>"Add Another Wallet"</strong> the{' '}
                  <strong>"Add an AMP Wallet"</strong> button.
                </li>

                <li>
                  Copy the liquid address which is called AMP ID starting with
                  the letters 'GA'.
                </li>
                <li>
                  For instructions on using the mobile app, please click this{' '}
                  <a
                    href="https://stokr.zendesk.com/hc/en-us/articles/4410573193617-How-to-connect-Liquid-Green-wallet-Mobile-Tablet-App-Version-3-7-5"
                    target="_blank"
                    rel="noreferrer"
                  >
                    link
                  </a>
                  .
                </li>
                <li>
                  You can also check our step by step guide video{' '}
                  <SpanButton
                    onlyText
                    onClick={() => this.setState({ isVideoOpen: true })}
                    style={{ color: 'inherit' }}
                    urlLink
                  >
                    here
                  </SpanButton>
                  .
                  <Video
                    src={registerLiquidVideo}
                    isVideoOpen
                    noPlayButton
                    play={this.state.isVideoOpen}
                    onClose={() => this.setState({ isVideoOpen: false })}
                  />
                </li>
              </ul>
            </LiquidDescription>
          </>
        ),
      },
    ]

    return (
      <FaqItems>
        <p style={{ marginBottom: 45 }}>
          All you have to do is take your liquid address from either blockchain
          wallets: SideSwap or Green Wallet. Once you've got that, just paste
          your liquid address in the designated box and then click 'Register'.
        </p>
        {items.map((item, index) => (
          <FaqItem key={item.title}>
            <FaqTitle
              isActive={activeIndex === index}
              onClick={(e) => this.onTitleClick(e, index)}
            >
              {item.title}
              <FaqDropdownIcon />
            </FaqTitle>
            <FaqContent isOpened={activeIndex === index}>
              <>
                <br />
                <Text>{item.content}</Text>
              </>
            </FaqContent>
          </FaqItem>
        ))}
      </FaqItems>
    )
  }
}

RegisterLiquidSteps.propTypes = {
  items: PropTypes.any,
  // items: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     title: PropTypes.string.isRequired,
  //     content: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  //       .isRequired,
  //   }),
  // ).isRequired,
}

export default RegisterLiquidSteps
