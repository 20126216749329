import styled, { css } from 'styled-components'
import rwd from 'styles/rwd'
import { Button, Row } from '@stokr/components-library'
import { FormField, FormError } from 'components/Form/Form'
import Text from 'components/Text/Text.styles'
import Icon, { iconsMap } from 'components/Icon/Icon.style'

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 33px 0;

  ${Text} {
    p {
      ${rwd.XLarge`
        font-size: 16px;
      `}
    }
  }
`

export const Header = styled.div`
  min-height: 82px;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 24px;
  flex-shrink: 0;

  ${rwd.Large`
    padding: 21px 64px;
  `}
`

export const HeaderTitle = styled.div`
  font-family: 'Open Sans';
  font-weight: 800;
  font-size: 22px;
  line-height: 1.27em;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  ${(props) => props.withMargin && 'margin-top: 16px;'}
`

export const HeaderButtonWrapper = styled.div`
  margin: 0 auto;
  padding: 8px 0;

  & > * {
    display: inline-block !important;
  }

  ${rwd.Large`
    margin-right: 0;
  `}

  ${Button}${Button}${Button} {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Body = styled.div`
  padding: 21px 24px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;

  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  ${(props) =>
    props.noVerticalPadding &&
    `
    padding-top: 0!important;
    padding-bottom: 0!important;
  `}

  ${(props) =>
    props.noHorizontalPadding &&
    `
    padding-left: 0;
    padding-right: 0;
  `}

  ${(props) =>
    props.form &&
    css`
      padding: 21px 48px;
      justify-content: flex-start;

      ${Row} {
        flex-shrink: 0;
      }
    `}
`

export const BottomWrapper = styled.div`
  ${FormField} {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${rwd.Medium`
      display: inline-block;
      vertical-align: middle;
      margin-right: 44px;
    `}

    + ${FormField} {
      padding-top: 32px;

      ${rwd.Medium`
        padding-top: 0;
      `}
    }

    ${FormError} {
      white-space: nowrap;
      padding-left: 28px;
    }
  }
`

export const BodyTitle = styled.div`
  text-align: center;
  margin: 0 auto;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.82;
  letter-spacing: 2px;

  ${(props) =>
    props.faceScan &&
    `
    margin-top: 20px;
  `}
`

export const ColumnInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${(props) =>
    props.verticalPadding &&
    `
    padding-top: 40px;
    padding-bottom: 40px;
  `}

  ${(props) =>
    props.horizontalPadding &&
    `
    padding-left: 36px;
    padding-right: 36px;
  `}

  ${(props) =>
    props.formInner &&
    `
    padding-left: 18px;
    padding-right: 18px;
    align-items: stretch;
  `}
  
  ${(props) =>
    props.withSeparator &&
    `
    border-bottom: 1px solid ${props.theme.cLightGrey};
  `}

  ${(props) =>
    props.verticalCenter &&
    `
    justify-content: center;
  `}

  ${(props) =>
    props.verticalSpaceAround &&
    `
    justify-content: space-around;
  `}

  ${rwd.Medium`
    ${(props) =>
      props.withSeparator &&
      `
      border-bottom: 0;
      border-right: 1px solid ${props.theme.cLightGrey};
    `}
  `}
`

export const FileInput = styled.input.attrs((props) => ({
  type: 'file',
  ...props,
}))`
  visibility: hidden;
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  z-index: -1;
`

export const FileInputWrapper = styled(Button).attrs({
  as: 'label',
  type: undefined,
})`
  position: relative;
  word-break: break-all;
`

export const FileInputRemove = styled(Icon).attrs({
  icon: iconsMap.trash,
})`
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s color;
  color: ${(props) => props.theme.cWarning};
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.cLightGrey};
  padding: 10px;

  &:hover {
    color: ${(props) => props.theme.cPrimary};
  }
`

export const SuccessMark = styled(Icon).attrs({
  icon: iconsMap.check,
})`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.cSuccess};
  margin: auto;
  font-size: 18px;

  ${(props) =>
    props.isHidden &&
    `
    opacity: 0;
  `}
`

export const FileInputList = styled.ul`
  list-style-type: none;
  padding: 0;
  font-size: 12px;
  width: 100%;
`

export const FileInputItem = styled.li`
  padding: 0 46px 0 8px;
  margin-right: 8px;
  position: relative;
  word-break: break-all;
`

const DocumentScanButtonsStyle = css`
  width: 40px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 50%;
  background: ${(props) => props.theme.cPrimary};
  color: ${(props) => props.theme.cWhite};
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  & > svg {
    fill: ${(props) => props.theme.cWhite};
    stroke: ${(props) => props.theme.cWhite};
  }
`

export const RetakeButton = styled.button`
  ${DocumentScanButtonsStyle};

  & > svg {
    width: 22px !important;
    height: 23px !important;
  }
`

export const DoneButton = styled.button.attrs({
  className: 'ion ion-md-checkmark',
})`
  ${DocumentScanButtonsStyle};
`

export const Mask = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(201, 255, 233, 0.6);
  position: relative;
`

export const FormBottomRow = styled(Row)`
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 64px;
`

export const FormBottomButtonWrap = styled.div`
  padding-top: 32px;
  margin: auto;

  ${rwd.Medium`
    padding-top: 0;
    margin-right: 0;
  `}
`
