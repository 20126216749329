import React, { useState, useContext, useEffect } from 'react'
import { Modal, ModalInner, ModalBack } from 'components/Modal/Modal'
import { DisplayOnBreakpoint } from 'styles/rwd'
import Text from 'components/Text/Text.styles'
import {
  StepController,
  StepControllerProvider,
  StepControllerConsumer,
  AuthContext,
} from '@stokr/components-library'
import {
  AlgoAddressName,
  AlgoAdressSelect,
  AlgoIntro,
  AlgoSuccess,
  SelectProject,
} from 'components/RegisterAlgorand/index'
import { makeOptInTx } from 'api/StokrWeb3'
import fetchData from 'api/fetchData'

const stepsNames = [
  'project',
  'wallet',
  'select-address',
  'address-name',
  // 'opt-in',
]

const AlgoAddressFlowPage = ({
  showFlow,
  setShowFlow,
  setShowPending,
  withSuccessPage = false,
  project = {},
}) => {
  const { updateUser, user } = useContext(AuthContext)

  const [wallet, setWallet] = useState(null)
  const [addressToRegister, setAddressToRegister] = useState(null)
  const [registeredAddress, setRegisteredAddress] = useState(false)
  const [nameToRegister, setNameToRegister] = useState(null)
  const [isUpdatingUser, setIsUpdatingUser] = useState('')
  const [error, setError] = useState('')
  const [showSuccess, setshowSuccess] = useState(false)
  const [algoProjects, setalgoProjects] = useState([])
  const [selectedProject, setselectedProject] = useState(project)
  const [verifiedAddress, setverifiedAddress] = useState(false)

  useEffect(() => {
    async function getAlgoProjects() {
      const projects = await fetchData('project/public/get', {
        noMetaData: true,
        tokenIssuanceType: 'algorand',
      })

      let projectswithPrimaryAsset =
        projects && projects.projects.filter((x) => x.primaryAssetId !== null)
      setalgoProjects(projectswithPrimaryAsset)
    }
    getAlgoProjects()
  }, [])

  const onBackButtonClick = (stepController, prevStepIndex) => {
    if (prevStepIndex === 0) {
      setselectedProject({})
    }
    if (prevStepIndex === 0 || prevStepIndex === 1) {
      setWallet(null)
      setAddressToRegister(null)
      setNameToRegister(null)
      setRegisteredAddress(false)
      setError('')
      setverifiedAddress(false)
    }

    stepController.changeStep(stepsNames[prevStepIndex], prevStepIndex)
  }

  const handleOnSubmit = async () => {
    setIsUpdatingUser(true)
    error && setError('')

    try {
      await updateUser({
        investor_algo_addresses: [
          {
            name: nameToRegister,
            address: addressToRegister,
          },
        ],
      })

      setIsUpdatingUser(false)

      if (!withSuccessPage) {
        setShowFlow(false)
        setShowPending(true)
      } else {
        setshowSuccess(true)
      }
    } catch (error) {
      setIsUpdatingUser(false)
      setError('Something went wrong. Please try again.')
    }
  }

  const handleOptIn = async (
    address = addressToRegister,
    registeredAlready = false,
  ) => {
    setIsUpdatingUser(true)
    verifiedAddress && setverifiedAddress(false)
    error && setError('')

    try {
      let finished = await makeOptInTx(
        selectedProject.primaryAssetId,
        address,
        address,
        wallet,
        'TestNet',
      )
      //let finished = true

      if (finished) {
        //update user algo address only if the opt in went good
        if (!registeredAlready) {
          await updateUser({
            investor_algo_addresses: [
              {
                address,
              },
            ],
          })
        }
        setverifiedAddress(true)
        setIsUpdatingUser(false)
      }
    } catch (error) {
      console.log('🚀 ~ error', error)
      setIsUpdatingUser(false)
      setverifiedAddress(false)

      //error code 429

      // setError('Something went wrong. Please try again.')
    }
  }

  return (
    <Modal fullscreen isOpen={showFlow} onClose={() => setShowFlow(false)}>
      <DisplayOnBreakpoint fullscreen down breakpoint="Medium">
        <ModalInner center>
          <Text center>
            <p>Currently not available on mobile</p>
          </Text>
        </ModalInner>
      </DisplayOnBreakpoint>

      <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
        <ModalInner noPadding>
          {showSuccess ? (
            <AlgoSuccess
              onClick={() => {
                setshowSuccess(false)
                setShowFlow(false)
              }}
            />
          ) : (
            <StepControllerProvider>
              <StepControllerConsumer>
                {(stepController) => {
                  const prevStepIndex = stepController.activeStepIndex - 1

                  return (
                    <>
                      <ModalBack
                        onClick={() =>
                          onBackButtonClick(stepController, prevStepIndex)
                        }
                        hide={!selectedProject.name}
                      />
                      <StepController
                        stepsProgressList={[
                          {
                            id: 'project',
                            handleClick: () => {
                              stepController.changeStep('project', 0)
                              setWallet(null)
                            },
                          },
                          {
                            id: 'wallet',
                            handleClick: () => {
                              stepController.changeStep('wallet', 1)
                              setWallet(null)
                            },
                          },

                          {
                            id: 'select-address',
                            handleClick: () =>
                              stepController.changeStep('select-address', 2),
                          },
                          {
                            id: 'address-name',
                            handleClick: () =>
                              stepController.changeStep('address-name', 3),
                          },
                        ]}
                        {...stepController}
                      >
                        <SelectProject
                          id="project"
                          changeStep={() =>
                            stepController.changeStep('wallet', 1, 0)
                          }
                          selectedProject={selectedProject}
                          projects={algoProjects}
                          setselectedProject={setselectedProject}
                        />
                        <AlgoIntro
                          id="wallet"
                          changeStep={() => {
                            stepController.changeStep('select-address', 2, 1)
                            setverifiedAddress(false)
                          }}
                          setWallet={setWallet}
                        />

                        <AlgoAdressSelect
                          id="select-address"
                          changeStep={() => {
                            stepController.changeStep('address-name', 3, 2)
                          }}
                          setAddressToRegister={setAddressToRegister}
                          setRegisteredAddress={setRegisteredAddress}
                          wallet={wallet}
                          isUpdatingUser={isUpdatingUser}
                          userAddresses={user && user.investor_algo_addresses}
                          handleOptIn={handleOptIn}
                          projectAssetId={selectedProject.primaryAssetId}
                          error={error}
                          verifiedAddress={verifiedAddress}
                        />

                        <AlgoAddressName
                          id="address-name"
                          changeStep={() => {
                            if (!registeredAddress) {
                              handleOnSubmit()
                            } else {
                              if (!withSuccessPage) {
                                setShowFlow(false)
                                setShowPending(true)
                              } else {
                                setshowSuccess(true)
                              }
                            }
                          }}
                          registeredAddress={registeredAddress}
                          setNameToRegister={setNameToRegister}
                          error={error}
                          isUpdatingUser={isUpdatingUser}
                        />
                      </StepController>
                    </>
                  )
                }}
              </StepControllerConsumer>
            </StepControllerProvider>
          )}
        </ModalInner>
      </DisplayOnBreakpoint>
    </Modal>
  )
}

export default AlgoAddressFlowPage
