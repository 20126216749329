import React, { useContext, useState, useEffect } from 'react'
import { useCustomNav } from 'utils/customNavigate'
import Layout from 'components/Layout/Layout'
import { FormError } from 'components/Form/Form'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import {
  Button,
  Background,
  ComponentWrapper,
  Row,
  Column,
  AuthContext,
  useCooldown,
} from '@stokr/components-library'

import backgroundImage from 'static/images/covers/rocket.png'
import { DisplayOnBreakpoint } from 'styles/rwd'

const ResendActivationEmail = () => {
  const { user, resendActivationEmail } = useContext(AuthContext)
  const { email, emailVerified } = user
  const navigator = useCustomNav()
  const { isDisabled, secondsRemaining, startCooldown } = useCooldown(60)

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (emailVerified) {
      navigator.navigate('/')
    }
  }, [emailVerified])

  const handleResendActivationEmail = async () => {
    error && setError('')
    startCooldown()

    try {
      await resendActivationEmail(email)

      setSuccess(true)
    } catch (error) {
      setError('Something went wrong. Please try again.')
    }
  }

  return (
    <Layout signupFlow noLogout>
      <SEO title="Resend activation email" />
      <Row main>
        <Column part={8} mainFixed>
          <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
            <Background src={backgroundImage} mobileRatio="16:9" />
          </DisplayOnBreakpoint>
        </Column>

        <Column part={8} withBorder mainRelative>
          {success ? (
            <>
              <ComponentWrapper>
                <Text>
                  <h3>Activation link sent to you</h3>
                  <p>
                    We have sent you an activation link. Please check your
                    inbox.
                  </p>
                </Text>
              </ComponentWrapper>
            </>
          ) : (
            <>
              <ComponentWrapper>
                <Text>
                  <h3>Your account is not activated yet</h3>
                  <p>
                    Please activate your account by clicking on the activation
                    link we have sent to you.
                  </p>
                </Text>
              </ComponentWrapper>
            </>
          )}
          <ComponentWrapper>
            <Button
              onClick={() => handleResendActivationEmail()}
              disabled={isDisabled && !error}
            >
              RESEND ACTIVATION EMAIL
            </Button>

            <FormError withTopSpacing show={error}>
              {error}
            </FormError>

            {isDisabled && !error && (
              <FormError withTopSpacing show={isDisabled}>
                {`You can try again in ${secondsRemaining} seconds`}
              </FormError>
            )}
          </ComponentWrapper>
        </Column>
      </Row>
    </Layout>
  )
}

export default ResendActivationEmail
