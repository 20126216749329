import styled from 'styled-components'
import rwd from 'styles/rwd'

export const EthStep = styled.div`
  position: relative;
  height: 100%;
`

export const EthInfo = styled.div`
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 9px;
  line-height: 1.78em;
  letter-spacing: 0.4px;
  color: ${props => props.theme.cBlack};
  opacity: 0.5;
  ${props => props.center && 'text-align: center;'}

  ${rwd.Large`
    font-size: 12px;
  `}

  a {
    text-decoration: underline;
    transition: color 0.2s;

    &:hover {
      color: ${props => props.theme.cPrimary};
    }
  }
`
