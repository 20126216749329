import React, { useContext } from 'react'
import {
  Background,
  Button,
  TextButton,
  FAQ,
  ComponentWrapper,
  SectionTitle,
  Row,
  Column,
  CryptoAddress,
  AuthContext,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import backgroundImage from 'static/images/covers/rocket.png'
import ToDoTask from 'components/ToDoList/ToDoListTask'
import { ToDoTaskState } from 'components/ToDoList/ToDoListTask'
import { iconsMap } from 'components/Icon/Icon.style'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import { DisplayOnBreakpoint } from 'styles/rwd'

const RegisterEthereum = ({ setShowFlow }) => {
  const { user } = useContext(AuthContext)
  const { investor_eth_addresses } = user

  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background src={backgroundImage} mobileRatio="16:9" />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        <ComponentWrapper>
          <Text>
            <h1>REGISTER ETHEREUM ADDRESS</h1>
            <p>
              You'll need this to store your tokens, receive future profit
              distributions, and make investments using ether.
            </p>
            <p>
              To help prevent fraud, only registered (whitelisted) addresses can
              make transactions.
            </p>
            {investor_eth_addresses ? (
              <>
                <p>
                  You already registered the following{' '}
                  {investor_eth_addresses.length > 1 ? 'addresses' : 'address'}:{' '}
                </p>
                {investor_eth_addresses.map((address, i) => (
                  <CryptoAddress
                    key={address.address}
                    title={address.name}
                    address={address.address}
                    noBody
                    noBorderBottom={investor_eth_addresses.length === i + 1}
                    wrapped
                    borderBottom
                  />
                ))}
              </>
            ) : (
              <p>No address yet? We'll guide you through the process.</p>
            )}
          </Text>
        </ComponentWrapper>

        {investor_eth_addresses && (
          <ComponentWrapper noPaddingTop>
            <ToDoTask
              title={`Ethereum ${
                investor_eth_addresses.length > 1 ? 'Addresses' : 'Address'
              }`}
              message={`Your ${
                investor_eth_addresses.length > 1
                  ? 'addresses are'
                  : 'address is'
              } whitelisted`}
              state={ToDoTaskState.APPROVED}
              icon={iconsMap.check}
              notificationCount={investor_eth_addresses.length}
              greenNotification
            />
          </ComponentWrapper>
        )}

        <ComponentWrapper noPaddingTop>
          <Button
            onClick={() => {
              setShowFlow(true)
            }}
          >
            {investor_eth_addresses
              ? 'ADD ETH ADDRESS'
              : 'REGISTER ETH ADDRESS'}
          </Button>
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <TextButton
            onClick={() => {
              deleteRedirectCookieAndNavigate()
            }}
          >
            I'll do this later
          </TextButton>
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>

        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: "I don't have an Ethereum address, what now?",
                content:
                  'You can install the MetaMask wallet and create an Ethereum address in minutes. Just go to metamask.io and download the browser extension to set-up your wallet. (Chrome, Firefox, Opera or Brave browser required). You can continue here after that.',
              },
              {
                title: 'What is an Ethereum Address?',
                content:
                  'Your Ethereum address is where your investment tokens and profit distributions will be sent to. You can also use it to purchase tokens using ether, Ethereums cryptocurrency.',
              },

              {
                title: "What if I'd like to do this later?",
                content:
                  'You can always skip this step and complete it from the Checklist in your Investor Dashboard at a later point in time. However, you will not be able to invest before doing so.',
              },
            ]}
          />
        </ComponentWrapper>
      </Column>
    </Row>
  )
}

export default RegisterEthereum
