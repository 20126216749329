import React from 'react'
import styled from 'styled-components'
import Layout from 'components/Layout/Layout'
import { Button } from '@stokr/components-library'

const TextBig = styled.div`
  font-size: 2em;
  font-weight: bold;
  color: #ee220d;
  line-height: 1.5;
`

const Container = styled.div`
  border-bottom: 1px solid #ededed;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1886px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 2rem;
  }

  @media screen and (max-width: 767px) {
    border-top: 1px solid #ededed;
  }
`

const Main = styled.div`
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  height: 100vh;
  justify-content: center;
  padding: 20px;
  text-align: center;
  width: 100vw;
`

const NotFoundMessage = styled.div`
  font-size: 48px;
  font-weight: 800;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 60%;
  line-height: 1.5;

  h1 {
    color: #ee220d;
    margin-bottom: 0;
  }

  @media screen and (max-width: 991px) {
    width: 80%;
    font-size: 28px;
  }
`

const NotFoundPage = () => (
  <Layout>
    <Container>
      <Main>
        <NotFoundMessage>
          <TextBig>404</TextBig>
          OOPS! SORRY. WE'RE NOT SURE WHAT YOU WERE LOOKING FOR.
        </NotFoundMessage>
        {/* <P>
            You have been successfully unsubscribed from our newsletters.
        </P>
        */}
        <Button
          onClick={() => window.location.replace('https://stokr-staging.de/')}
        >
          Go to homepage
        </Button>
      </Main>
    </Container>
  </Layout>
)

export default NotFoundPage
