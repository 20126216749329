import React from 'react'
import { Background, Row, Column } from '@stokr/components-library'
import backgroundImage from 'static/images/covers/rocket.png'

import { useCustomNav } from 'utils/customNavigate'
import { RegisterTaxIdComplete } from '@stokr/components-library'
import redirectUrl from 'utils/redirect-url'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import { DisplayOnBreakpoint } from 'styles/rwd'

const RegisterTaxIdCompleteMain = () => {
  const navigator = useCustomNav()
  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background src={backgroundImage} mobileRatio="16:9" />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        <RegisterTaxIdComplete
          showBackButton={redirectUrl ? false : true}
          onClick={() => {
            if (redirectUrl) {
              deleteRedirectCookieAndNavigate()
            } else {
              navigator.navigate('/register-liquid-securities')
            }
          }}
        />
      </Column>
    </Row>
  )
}

export default RegisterTaxIdCompleteMain
