import React, { useState, useContext } from 'react'
import { useCustomNav } from 'utils/customNavigate'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import {
  Background,
  Button,
  TextButton,
  FAQ,
  SectionTitle,
  ComponentWrapper,
  Row,
  Column,
  AuthContext,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import { FormError } from 'components/Form/Form'
import backgroundImage from 'static/images/covers/rocket.png'
import telindusLogo from 'static/images/Telindus_logo.webp'
import redirectUrl from 'utils/redirect-url'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import ToDoTask, { ToDoTaskState } from 'components/ToDoList/ToDoListTask'
import { iconsMap } from 'components/Icon/Icon.style'
import { DisplayOnBreakpoint } from 'styles/rwd'

const Telindus = styled.img`
  top: 5px;
  position: relative;
  width: 80px;
  left: 8px;

  @media screen and (max-width: 398px) {
    width: 60px;
    top: 3px;
  }
`
export const RedirectSpacer = styled.div`
  height: 14px;
`

const VerifyIdentity = ({ setShowPending }) => {
  const { user, updateUser } = useContext(AuthContext)
  const { _id, kyc_status } = user
  const [isOpeningModal, setIsOpeningModal] = useState(false)
  const [error, setError] = useState('')
  const navigator = useCustomNav()

  const showVerifyIndentityButton =
    !kyc_status || kyc_status === 'Draft' || kyc_status === 'Reapply'

  const cancelledKYC = kyc_status === 'Cancelled'

  const onComplete = async () => {
    error && setError('')

    try {
      await updateUser({
        kyc_status: 'In Progress',
      })

      setShowPending(true)
    } catch (error) {
      setIsOpeningModal(false)
      setError('Something went wrong. Please try again.')
    }

    document.body.style.overflowY = 'unset'
  }

  const onClose = async () => {
    setIsOpeningModal(false)

    // Prevent 'Draft' from overwriting another status, e.g. 'Pending'
    if (!kyc_status) {
      try {
        await updateUser({
          kyc_status: 'Draft',
        })
      } catch (error) {}
    }

    document.body.style.overflowY = 'unset'
  }

  const openPopup = async () => {
    await window.digitalkyc.initialize(
      'https://stokr-uat.digitalkyc.com/onboarding/',
    )

    var options = {
      template: 'stokr.web',
      language: 'en',
      source: 'STOKR',
      reference: _id,
      onCancelled: () => onClose(),
      onCompleted: () => onComplete(),
    }

    try {
      window.digitalkyc.register(options)
    } catch (error) {
      setIsOpeningModal(false)
      setError('Something went wrong. Please try again.')
      console.log(`Error opening Digital KYC modal: ${error}`)
    }
  }

  return (
    <>
      <Helmet>
        <script
          id="digitalkyc-integration"
          src="https://stokr-uat.digitalkyc.com/onboarding/assets/global/scripts/popup.js"
        />
      </Helmet>
      <Row main>
        <Column part={8} mainFixed>
          <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
            <Background src={backgroundImage} mobileRatio="16:9" />
          </DisplayOnBreakpoint>
        </Column>

        <Column part={8} mainRelative>
          {redirectUrl ? (
            <RedirectSpacer />
          ) : (
            <ComponentWrapper noPaddingVertical>
              <TextButton
                onClick={() => navigator.navigate('/country-of-residence')}
              >
                Back to Country of residence
              </TextButton>
            </ComponentWrapper>
          )}

          <ComponentWrapper>
            {user.countryObj?.signUpWarning ? (
              <Text>
                <h1>
                  INVESTING IN PUBLIC ROUNDS IS NOT AVAILABLE FROM YOUR
                  RESIDENCE YET.
                </h1>
                <p>
                  To participate in private rounds, please continue signing up
                  so we can fully verify you as an investor. However, to
                  participate in private rounds you require a special invite
                  link which is sent to eligible investors only.
                </p>
                <p>
                  Powered by Digital KYC
                  <Telindus src={telindusLogo} />
                </p>
                {/* <p
                  style={{
                    color: 'rgb(238, 34, 13)',
                    fontSize: 16,
                    textTransform: 'uppercase',
                    fontWeight: 600,
                  }}
                >
                  ! Please bear with us. We are currently updating the Digital
                  KYC process. Please reach out to{' '}
                  <a
                    href="mailto:compliance@stokr.io"
                    style={{
                      color: 'black',
                      textDecoration: 'none',
                      fontWeight: 600,
                    }}
                  >
                    compliance@stokr.io
                  </a>{' '}
                  if you are experiencing any problems !
                </p> */}
              </Text>
            ) : (
              <Text>
                <h1>VERIFY YOUR IDENTITY</h1>
                <p>
                  Before you can invest please verify your identity with your
                  passport or ID card.
                </p>
                <p>
                  Powered by Digital KYC
                  <Telindus src={telindusLogo} />
                </p>
                {/* <p
                  style={{
                    color: 'rgb(238, 34, 13)',
                    fontSize: 16,
                    textTransform: 'uppercase',
                    fontWeight: 600,
                  }}
                >
                  ! Please bear with us. We are currently updating the Digital
                  KYC process. Please reach out to{' '}
                  <a
                    href="mailto:compliance@stokr.io"
                    style={{
                      color: 'black',
                      textDecoration: 'none',
                      fontWeight: 600,
                    }}
                  >
                    compliance@stokr.io
                  </a>{' '}
                  if you are experiencing any problems !
                </p> */}
              </Text>
            )}
          </ComponentWrapper>

          {/* this logic should be moved to pending page */}
          {showVerifyIndentityButton ? (
            <>
              <ComponentWrapper noPaddingTop>
                <Button onClick={openPopup} disabled={isOpeningModal}>
                  Verify Identity
                </Button>

                <FormError withTopSpacing show={error}>
                  {error}
                </FormError>
              </ComponentWrapper>

              <ComponentWrapper noPaddingTop>
                <TextButton
                  onClick={() => {
                    if (redirectUrl) {
                      deleteRedirectCookieAndNavigate()
                    } else {
                      navigator.navigate('/taxid')
                    }
                  }}
                >
                  I'll do this later
                </TextButton>
              </ComponentWrapper>
            </>
          ) : (
            <>
              <ComponentWrapper noPaddingTop>
                <ToDoTask
                  title="Verify Identity"
                  message={
                    cancelledKYC ? (
                      <>
                        Please contact{' '}
                        <a
                          href="mailto:support@stokr.io"
                          style={{ textDecoration: 'underline' }}
                        >
                          support@stokr.io
                        </a>
                      </>
                    ) : (
                      'Our anti-fraud squad is still working'
                    )
                  }
                  state={ToDoTaskState.IN_PROGRESS}
                  icon={cancelledKYC ? iconsMap.warning : iconsMap.inProgress}
                />
              </ComponentWrapper>
              <ComponentWrapper noPaddingTop>
                <Button
                  onClick={() => {
                    if (redirectUrl) {
                      deleteRedirectCookieAndNavigate()
                    } else {
                      navigator.navigate('/taxid')
                    }
                  }}
                >
                  Continue
                </Button>
              </ComponentWrapper>
            </>
          )}

          <ComponentWrapper noPaddingBottom>
            <SectionTitle>Frequently Asked Questions</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper>
            <FAQ
              items={[
                {
                  title:
                    'Why does my browser keep freezing during the KYC process?',
                  content:
                    'Your browser seems to block the identity verification pop-up window. Please deactivate any pop-up blocker and reload the page. If you are using the Brave web browser, you must disable the brave-shield located to the right of the address bar. Don’t worry, you can turn it back on right after.',
                },
                {
                  title: 'Why do I need to show an ID document?',
                  content:
                    "We use ID verification in order to comply with Know Your Customer (KYC) and Anti Money Laundering (AML) regulations. In short, check if you're legally allowed to invest.",
                },
                {
                  title: 'How will my information be used?',
                  content:
                    "Your ID information will never be sold, and never shown publicly. We just use it to make sure you're legally allowed to invest through the STOKR interface.",
                },
                {
                  title: "What if I'd like to do this later?",
                  content:
                    'You can always skip this step and complete it from the Checklist in your Investor Dashboard at a later point in time. However, you will not be able to invest before doing so and being verified.',
                },
              ]}
            />
          </ComponentWrapper>
        </Column>
      </Row>
    </>
  )
}

export default VerifyIdentity
