import React, { useContext, useEffect } from 'react'
import { AuthContext } from '@stokr/components-library'
import { useCustomNav } from 'utils/customNavigate'

// This page has no content to render, though it handles
// where to redirect the user in case '/' is visited
const Index = () => {
  const { user } = useContext(AuthContext)
  const { country, entity_name, emailVerified } = user
  const navigator = useCustomNav()

  useEffect(() => {
    if (user) {
      if (!emailVerified) {
        navigator.navigate('/resend-activation-email')
      } else if (country) {
        navigator.navigate('/verify-identity')
      } else if (entity_name) {
        navigator.navigate('/taxid')
      } else {
        navigator.navigate('/welcome')
      }
    }
  }, [])

  return <div />
}

export default Index
