import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '@stokr/components-library'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import RiskQuestionnaire from './risk-questionnaire'
import RiskQuestionnaireFlow from './flow'
import RiskQuestionnaireReview from './review'
import RiskQuestionnaireComplete from './complete'
import { useCustomNav } from 'utils/customNavigate'

const RiskQuestionnairePage = () => {
  const { user } = useContext(AuthContext)
  const { is_risk_questionnaire_finished, emailVerified } = user
  const navigator = useCustomNav()

  const [showFlow, setShowFlow] = useState(false)
  const [showReview, setShowReview] = useState(false)
  const [showComplete, setShowComplete] = useState(false)

  useEffect(() => {
    if (!emailVerified) {
      navigator.navigate('/resend-activation-email')
    }
  }, [])

  const renderPages = () => {
    if (is_risk_questionnaire_finished || showComplete) {
      return <RiskQuestionnaireComplete setShowReview={setShowReview} />
    }

    return <RiskQuestionnaire setShowFlow={setShowFlow} />
  }

  return (
    <Layout signupFlow progress>
      <SEO title="Risk Questionnaire" />
      {renderPages()}

      <RiskQuestionnaireFlow
        showFlow={showFlow}
        setShowFlow={setShowFlow}
        setShowComplete={setShowComplete}
      />

      <RiskQuestionnaireReview
        showReview={showReview}
        setShowReview={setShowReview}
      />
    </Layout>
  )
}

export default RiskQuestionnairePage
