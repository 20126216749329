import React from 'react'
import { useNavigate } from 'react-router-dom'

export const useCustomNav = () => {
  const navigate = useNavigate()

  return { navigate }
}

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigation = useNavigate()

    return <Component navigate={navigation} {...props} />
  }

  return Wrapper
}
