import styled from 'styled-components'
import BaseIcon from 'components/Icon/Icon.style'
import { ToDoTaskState } from './ToDoListTask'

export const Container = styled.div`
  display: flex;
  height: 56px;
  align-items: center;
  ${(props) =>
    props.disabled &&
    `
    opacity:0.5;
    cursor: context-menu;
  `}
`
export const WarningTitle = styled.span`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 11.5px;
  line-height: 12px;
  color: ${(props) => props.theme.cWarning};
  padding-left: 10px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-align: left;

  ${(props) =>
    (props.state === ToDoTaskState.IN_PROGRESS ||
      props.state === ToDoTaskState.APPROVED ||
      props.state === ToDoTaskState.NOT_AVAILABLE) &&
    `
    opacity: 0.3;
  `}

  ${(props) =>
    props.link &&
    `
    opacity: 1;
  `}
`

export const Notifications = styled.div`
  position: relative;
  right: -5px;
  width: 16px;
  height: 16px;
  color: #ffffff;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  background-color: #ee220d;
  border-radius: 50%;
  padding-left: 0;
  ${(props) => props.green && `background-color:#79ca65`}
`

export const State = styled.p`
  margin-top: 3px;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-weight: 300;
  text-align: left;
  opacity: 0.6;
`

export const IconContainer = styled.div`
  flex-shrink: 0;
  width: 23px;
  text-align: center;
  /* margin: auto 0 auto auto; */

  margin-right: 10px;
  position: relative;
  top: -10px;
`

export const TooltipIcon = styled.i.attrs({
  className: 'ion ion-md-alert',
})`
  font-size: 18px;
  margin: 0 14px;
  opacity: 0.2;
`

export const TooltipBox = styled.div`
  max-width: 240px;
  text-align: left;
`

export const Icon = styled(BaseIcon)`
  font-size: 20px;
  width: 18px;
  text-align: center;

  ${(props) =>
    props.state === ToDoTaskState.APPROVED &&
    `
    font-size: 13px;
    color: ${props.theme.cSuccess};
  `}

  ${(props) =>
    props.state === ToDoTaskState.REJECTED &&
    `
    color: ${props.theme.cWarning};
  `}

  ${(props) =>
    props.state === ToDoTaskState.IN_PROGRESS &&
    `
    opacity: 0.3;
  `}

  ${(props) =>
    props.state === ToDoTaskState.SKIPPED &&
    `
    font-size: 13px;
  `}

  ${(props) =>
    props.state === ToDoTaskState.NOT_AVAILABLE &&
    `
    font-size: 13px;
    opacity: 0.3;
  `}
`
