import React, { useContext, useState } from 'react'
import { useCustomNav } from 'utils/customNavigate'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Background,
  Button,
  ComponentWrapper,
  FAQ,
  SectionTitle,
  Row,
  Column,
  AuthContext,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import Form, { FormField, FormError } from 'components/Form/Form'
import Select from 'components/Input/Select'
import ToDoTask from 'components/ToDoList/ToDoListTask'
import { ToDoTaskState } from 'components/ToDoList/ToDoListTask'
import { iconsMap } from 'components/Icon/Icon.style'
import backgroundImage from 'static/images/covers/rocket.png'
import countriesList from 'utils/countries-list'
import { DisplayOnBreakpoint } from 'styles/rwd'
import { RedirectSpacer } from 'pages/verify-identity/verify-identity'

export const CountryOfResidence = ({
  setIsCountryNotAllowed,
  setSelectedCountry,
}) => {
  const { user, updateUser, sendWelcomeEmail } = useContext(AuthContext)
  const { country, kyc_status } = user
  const navigator = useCustomNav()

  const [isUpdatingUser, setIsUpdatingUser] = useState(false)
  const [error, setError] = useState('')

  const isStepDone = kyc_status && kyc_status !== 'Draft' && country

  const initialValues = {
    country: country || '',
  }

  const validationSchema = Yup.object().shape({
    country: Yup.string().required('This field is required'),
  })

  const handleOnSubmit = async (e) => {
    setIsUpdatingUser(true)

    const selectedCountry = e.country

    if (!isStepDone) {
      try {
        const updatedUser = await updateUser({
          country: selectedCountry,
        })

        if (updatedUser.countryObj?.isAllowed) {
          if (!country) {
            sendWelcomeEmail() // Doesn't throw error if it fails
          }

          navigator.navigate('/verify-identity')
        } else {
          setIsCountryNotAllowed(true)
          setSelectedCountry(selectedCountry)
        }
      } catch (error) {
        console.log('🚀 ~ error:', error)
        setError('Something went wrong. Please try again.')
        setIsUpdatingUser(false)
      }
    } else {
      navigator.navigate('/verify-identity')
    }
  }

  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background src={backgroundImage} mobileRatio="16:9" />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        <RedirectSpacer />
        <ComponentWrapper>
          <Text>
            <h1>ENTER YOUR COUNTRY OF RESIDENCE</h1>

            {isStepDone ? (
              <p>
                Thank you for coming back. You registered already the following
                country of residence: {country}.
              </p>
            ) : (
              <p>
                Please choose your residency. Depending on where you live you
                will have different investment options.
              </p>
            )}
          </Text>
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(e) => {
              handleOnSubmit(e)
            }}
          >
            {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
              const handleChangeSelect = (field) => {
                setFieldValue(field.name, field.value, false)
                setFieldTouched(field.name, true, false)
              }

              const handleBlurSelect = (field) => {
                setFieldTouched(field.name)
              }

              const submitDisabled = !values.country || isUpdatingUser

              return (
                <Form>
                  <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                    <FormField>
                      <Select
                        id="country-of-residence-country"
                        name="country"
                        options={countriesList.map(({ name }) => ({
                          key: name,
                          value: name,
                          label: name,
                        }))}
                        label="What is your country of residence?"
                        placeholder="Select country"
                        value={values.country}
                        onChange={handleChangeSelect}
                        //onBlur={handleBlurSelect}
                        error={!!errors.country}
                        touched={touched.country}
                        search
                        disabled={isStepDone}
                      />

                      {isStepDone && (
                        <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                          <ToDoTask
                            title="Country of Residence"
                            message="Your country of residence has been registered"
                            state={ToDoTaskState.APPROVED}
                            icon={iconsMap.check}
                          />
                        </ComponentWrapper>
                      )}

                      <FormError show={errors.country && touched.country}>
                        {errors.country}
                      </FormError>
                    </FormField>
                  </ComponentWrapper>
                  <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                    <Button type="submit" disabled={submitDisabled}>
                      Continue
                    </Button>

                    <FormError withTopSpacing show={error}>
                      {error}
                    </FormError>
                  </ComponentWrapper>
                </Form>
              )
            }}
          </Formik>
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>

        <ComponentWrapper>
          <FAQ
            items={[
              {
                title:
                  'Why do I need to provide you with my country of residence?',
                content:
                  'Your residence location determines the rules for investors. Generally, STOKR works with European capital market rules, so investing from a European member state is often possible. However, some countries impose stricter rules compared to the EU and makes it harder to invest in innovative projects. We work hard to look into each country rules and regulations but sometimes they are not friendly enough and we have to close them. Sorry for that.',
              },
            ]}
          />
        </ComponentWrapper>
      </Column>
    </Row>
  )
}

export default CountryOfResidence
