import React, { useState, useContext } from 'react'
import { Modal, ModalInner, ModalBack } from 'components/Modal/Modal'
import { DisplayOnBreakpoint } from 'styles/rwd'
import Text from 'components/Text/Text.styles'
import {
  EthIntro,
  EthConnectLedger,
  EthConnectMetamask,
  EthAddressLedger,
  EthAddressMetamask,
  EthAddressName,
  EthStep,
} from 'components/RegisterEthereum'
import {
  StepController,
  StepControllerProvider,
  StepControllerConsumer,
  AuthContext,
} from '@stokr/components-library'

const stepsNames = [
  'wallet',
  'connect',
  'select-address',
  'address-name',
  'register-finish',
]

const EthAddressFlowPage = ({ showFlow, setShowFlow, setShowPending }) => {
  const { updateUser } = useContext(AuthContext)

  const [wallet, setWallet] = useState(null)
  const [addressToRegister, setAddressToRegister] = useState(null)
  const [nameToRegister, setNameToRegister] = useState(null)
  const [isUpdatingUser, setIsUpdatingUser] = useState('')
  const [error, setError] = useState('')

  const onBackButtonClick = (stepController, prevStepIndex) => {
    if (prevStepIndex === 0) {
      setWallet(null)
      setAddressToRegister(null)
      setNameToRegister(null)
    }

    stepController.changeStep(stepsNames[prevStepIndex], prevStepIndex)
  }

  const handleOnSubmit = async () => {
    setIsUpdatingUser(true)
    error && setError('')

    try {
      await updateUser({
        investor_eth_addresses: [
          {
            name: nameToRegister,
            address: addressToRegister,
          },
        ],
      })

      setShowFlow(false)
      setIsUpdatingUser(false)
      setShowPending(true)
    } catch (error) {
      setIsUpdatingUser(false)
      setError('Something went wrong. Please try again.')
    }
  }

  return (
    <Modal fullscreen isOpen={showFlow} onClose={() => setShowFlow(false)}>
      <DisplayOnBreakpoint fullscreen down breakpoint="Medium">
        <ModalInner center>
          <Text center>
            <p>Currently not available on mobile</p>
          </Text>
        </ModalInner>
      </DisplayOnBreakpoint>

      <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
        <ModalInner noPadding>
          <StepControllerProvider>
            <StepControllerConsumer>
              {(stepController) => {
                const prevStepIndex = stepController.activeStepIndex - 1

                return (
                  <>
                    <ModalBack
                      onClick={() =>
                        onBackButtonClick(stepController, prevStepIndex)
                      }
                      hide={!wallet}
                    />
                    <StepController
                      stepsProgressList={[
                        {
                          id: 'wallet',
                          handleClick: () => {
                            stepController.changeStep('wallet', 0)
                            setWallet(null)
                          },
                        },
                        {
                          id: 'connect',
                          handleClick: () =>
                            stepController.changeStep('connect', 1),
                        },
                        {
                          id: 'select-address',
                          handleClick: () =>
                            stepController.changeStep('select-address', 2),
                        },
                        {
                          id: 'address-name',
                          handleClick: () =>
                            stepController.changeStep('address-name', 3),
                        },
                      ]}
                      {...stepController}
                    >
                      <EthIntro
                        id="wallet"
                        changeStep={() =>
                          stepController.changeStep('connect', 1, 0)
                        }
                        setWallet={setWallet}
                      />

                      <EthStep id="connect">
                        {wallet === 'ledger' && (
                          <EthConnectLedger
                            changeStep={() =>
                              stepController.changeStep('select-address', 2, 1)
                            }
                          />
                        )}
                        {wallet === 'metamask' && (
                          <EthConnectMetamask
                            changeStep={() =>
                              stepController.changeStep('select-address', 2, 1)
                            }
                          />
                        )}
                      </EthStep>

                      <EthStep id="select-address">
                        {wallet === 'ledger' && (
                          <EthAddressLedger
                            changeStep={() =>
                              stepController.changeStep('address-name', 3, 2)
                            }
                            setAddressToRegister={setAddressToRegister}
                          />
                        )}
                        {wallet === 'metamask' && (
                          <EthAddressMetamask
                            changeStep={() =>
                              stepController.changeStep('address-name', 3, 2)
                            }
                            setAddressToRegister={setAddressToRegister}
                          />
                        )}
                      </EthStep>

                      <EthAddressName
                        id="address-name"
                        changeStep={() => {
                          handleOnSubmit()
                        }}
                        setNameToRegister={setNameToRegister}
                        error={error}
                        isUpdatingUser={isUpdatingUser}
                      />
                    </StepController>
                  </>
                )
              }}
            </StepControllerConsumer>
          </StepControllerProvider>
        </ModalInner>
      </DisplayOnBreakpoint>
    </Modal>
  )
}

export default EthAddressFlowPage
