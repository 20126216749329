/* Libraries */
import Cookies from 'js-cookie'
import axios from '../model/axios'

const fetchData = async (url, data) =>
  new Promise(async (resolve, reject) => {
    const accessToken = await Cookies.get('STOKR_ACCESS_TOKEN')
    const parsedData = JSON.stringify(data)

    if (!accessToken) {
      console.log('no access token')
    }

    try {
      const result = await axios({
        method: 'POST',
        url,
        data: parsedData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      // console.log(result);

      resolve(result.data)
    } catch (error) {
      console.log(`Error: ${error}`)

      reject(error)
    }
  })

export default fetchData
