import { PureComponent } from 'react'
import ReactDOM from 'react-dom'

export class RenderToBody extends PureComponent {
  componentDidMount() {
    this.outsideWrapper = document.createElement('div')
    document.body.appendChild(this.outsideWrapper)
    this.renderOutsideBody()
  }

  componentDidUpdate() {
    this.renderOutsideBody()
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.outsideWrapper)
    document.body.removeChild(this.outsideWrapper)
  }

  renderOutsideBody = () => {
    const { children } = this.props

    ReactDOM.render(children, this.outsideWrapper)
  }

  render() {
    return null
  }
}

export default RenderToBody
