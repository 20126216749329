import React from 'react'
import { useCustomNav } from 'utils/customNavigate'
import {
  Background,
  Button,
  TextButton,
  FAQ,
  SectionTitle,
  ComponentWrapper,
  Row,
  Column,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import backgroundImage from 'static/images/covers/rocket.png'
import redirectUrl from 'utils/redirect-url'
import { FormError } from '../../components/Form/Form'
import { DisplayOnBreakpoint } from 'styles/rwd'
import { RedirectSpacer } from './verify-identity'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'

const VerifyIdentityPendingPage = ({
  isKYCComplete,
  setShowPending,
  kycStatus,
}) => {
  const navigator = useCustomNav()
  const isKYCRejected = kycStatus && kycStatus === 'Rejected'
  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background src={backgroundImage} mobileRatio="16:9" />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        {!isKYCComplete && !redirectUrl ? (
          <ComponentWrapper noPaddingVertical>
            <TextButton
              onClick={() => navigator.navigate('/country-of-residence')}
            >
              Back to Country of residence
            </TextButton>
          </ComponentWrapper>
        ) : (
          <RedirectSpacer />
        )}

        <ComponentWrapper>
          <Text>
            <h1>VERIFICATION IN PROCESS</h1>
            <p>
              You will be notified when the process is completed.
              {!isKYCRejected && <> This typically takes up to 24 hours.</>}
            </p>
            {/* <FormError show>
              We are currently experiencing a high volume of verification
              applications.Our current wait time is around 2-6 days. Thank you
              for understanding.
            </FormError> */}
          </Text>
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <Button
            onClick={() => {
              if (redirectUrl) {
                deleteRedirectCookieAndNavigate()
              } else {
                navigator.navigate('/taxid')
              }
            }}
          >
            Continue
          </Button>
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>

        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: 'How long does verification typically take?',
                content:
                  'Identity verification usually takes no longer than 30 minutes. Sometimes it may take longer, in which case you will get informed typically within 24 hours.',
              },
              {
                title: 'Can I invest before being verified?',
                content:
                  'Your identity needs to be verified in order to invest through the STOKR interface. You can, however, use many of the other features including the community before being verified.',
              },
            ]}
          />
        </ComponentWrapper>
      </Column>
    </Row>
  )
}

export default VerifyIdentityPendingPage
