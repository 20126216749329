import React from 'react'
import PropTypes from 'prop-types'

import Text from 'components/Text/Text.styles'
import {
  Button,
  SectionTitle,
  ComponentWrapper,
  ComponentScroll,
} from '@stokr/components-library'

import { ModalWrapper } from 'components/Modal/Modal.styles'

import { isMetamaskInstalled } from 'api/StokrWeb3'

const EthConnectMetamask = ({ changeStep }) => (
  <ModalWrapper>
    <ComponentWrapper>
      <SectionTitle>Register Ethereum address</SectionTitle>
    </ComponentWrapper>

    <ComponentScroll>
      <ComponentWrapper noPaddingVertical>
        <Text>
          <h3>Connect to metamask</h3>
          <p>
            {
              'Ensure your MetaMask extension is enabled in your browser. Then click ‘Connect to Metamask’. In the pop up that is presented please give STOKR permission to view your current account address.'
            }
          </p>
        </Text>
      </ComponentWrapper>

      <ComponentWrapper center>
        <Button
          minWidth="240px"
          onClick={changeStep}
          disabled={!isMetamaskInstalled()}
        >
          Connect
        </Button>
      </ComponentWrapper>

      {/* <ComponentWrapper noPaddingBottom>
        <SectionTitle>Frequently Asked Questions</SectionTitle>
      </ComponentWrapper>

       <ComponentWrapper>
        <FAQ
          items={[
            {
              title: "How do I know this is secure?",
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt venenatis sapien, quis efficitur augue tincidunt vel. Praesent vel laoreet est, a imperdiet erat."
            }
          ]}
        />
      </ComponentWrapper> */}
    </ComponentScroll>
  </ModalWrapper>
)

EthConnectMetamask.propTypes = {
  changeStep: PropTypes.func.isRequired,
}

export default EthConnectMetamask
