import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import grid from 'styles/grid'
import rwd from 'styles/rwd'

export const ModalRoot = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const Dimmer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 0;
  text-align: center;
  overflow-y: auto;

  ${(props) => props.kyc && 'overflow: visible;'}

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
  }
`

export const Box = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  background-color: ${(props) => props.theme.cWhite};
  font-size: 10px;
  text-align: left;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

  ${(props) =>
    props.fullscreen &&
    `
    height: 90vh;
  `}

  ${(props) =>
    props.kyc &&
    `
    width: 100vw;
    height: 100vh;
    max-width: 1024px!important;
    max-height: 768px!important;
  `}

  ${rwd.Medium`
    max-width: ${grid(12)};

    ${(props) =>
      props.thin &&
      `
      max-width: ${grid(8)};
    `}
  `}

  ${rwd.XLarge`
    max-width: ${grid(8)};

    ${(props) =>
      props.thin &&
      `
      max-width: ${grid(6)};
    `}
  `}

  ${({ isRegisterEntity }) =>
    isRegisterEntity &&
    css`
      max-width: 55% !important;
      height: 65vh;
    `}
`

export const ModalInnerSmartContractAddress = styled.input`
  color: ${(props) => props.theme.cBlack};
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-top: 24px;
  width: 100%;
  apperance: none;
  resize: none;
  border: none;
  outline: 0;
`

export const ModalInnerWithBorder = styled.div`
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.cGrey};
  padding: 25px 35px;
  margin-top: 39px;

  ${(props) =>
    props.bottomRadius0 &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}

  ${(props) =>
    props.topRadius0 &&
    `
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}

  ${(props) =>
    props.marginTop0 &&
    `
    margin-top: 0;
  `}

  ${(props) =>
    props.bordderTopNone &&
    `
    border-top: 0;
  `}

  ${(props) =>
    props.textAlignRight &&
    `
    text-align: right;
  `}

  ${(props) =>
    props.paddingSmall &&
    `
    padding: 21px 25px;
  `}
  
  p {
    color: ${(props) => props.theme.cBlack};
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.53px;
    line-height: 22px;
    text-align: left;
    margin: 0;
  }
`

export const ModalInner = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px ${grid(1)};
  display: flex;
  flex-direction: column;
  overflow: scroll;

  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}

  ${(props) =>
    props.background &&
    `
    background-color: ${props.theme.cGrey};
  `}

  ${(props) =>
    props.modalBot &&
    `
    background-color: ${props.theme.cWarning};
    color: ${props.theme.cWhite};
    flex-grow: 0 !important;
    height: auto;
  `}

  ${(props) =>
    props.modalTop &&
    `
    padding-bottom: 30px;
  `}

  ${(props) =>
    props.center &&
    `
    justify-content: center;
    align-items: center;
  `}

  ${rwd.Medium`
    padding: 53px ${grid(1, 12)};

    ${(props) =>
      props.left &&
      `
      padding: 88px ${grid(1, 12)};
    `}

    ${(props) =>
      props.modalTop &&
      `
      padding-bottom: 43px;
      border-right: 1px solid ${props.theme.cGrey};
    `}

    /* missing tiny */
  `}

  ${rwd.XLarge`
    padding: 53px ${grid(1, 8)};

    ${(props) =>
      props.left &&
      `
      padding: 88px ${grid(1, 8)};
    `}

    ${(props) =>
      props.modalTop &&
      `
      padding-bottom: 53px;
    `}

    /* missing tiny */
  `}

  ${(props) =>
    props.noPadding &&
    `
    padding: 0!important;
  `}

  ${(props) =>
    props.noPaddingHorizontal &&
    `
    padding-left: 0!important;
    padding-right: 0!important;
  `}
`

export const ModalClose = styled.i.attrs({
  className: 'ion ion-md-close',
})`
  z-index: 4;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.cWhite};
  background-color: ${(props) => props.theme.cPrimary};
  transition: background-color 0.2s, color 0.2s;

  &:before {
    transition: transform 0.2s;
    font-size: 24px;
    line-height: 24px !important;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }

  &:hover {
    color: ${(props) => props.theme.cBlack};
    background-color: ${(props) => props.theme.cWhite};

    &:before {
      transform: rotate(-180deg);
    }
  }

  ${rwd.Medium`
    width: 56px;
    height: 56px;

    &:before {
      font-size: 27px;
    }
  `}
`

export const ModalBack = styled.i.attrs({
  className: 'ion ion-ios-arrow-back',
})`
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.cBlack};
  background-color: transparent;
  transition: color 0.2s, opacity 0.2s, visibility 0.2s;

  ${(props) =>
    props.hide &&
    `
    opacity: 0;
    visibility: hidden;
  `}

  &:before {
    transition: transform 0.2s;
    font-size: 24px;
    line-height: 24px !important;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }

  &:hover {
    color: ${(props) => props.theme.cPrimary};

    &:before {
      transform: translateX(-20%);
    }
  }

  ${rwd.Medium`
    width: 56px;
    height: 56px;

    &:before {
      font-size: 27px;
    }
  `}
`

export const ModalLinkWrap = styled.div`
  position: relative;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  padding-left: 18px;

  &:before {
    content: '\\f3d1';
    font-family: 'Ionicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: 8px;
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -4px;
    color: ${(props) => props.theme.cLighterGreen};
  }
`

export const ModalLink = styled(Link)`
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: inherit;

  &:before {
    content: '';
    display: block;
    width: 0%;
    height: 1px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: ${(props) => props.theme.cLighterGreen};
    transition: width 0.2s;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
`

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ModalTitle = styled.div`
  font-family: 'Open Sans';
  font-weight: 800;
  font-size: 22px;
  line-height: 1.27em;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`

export const ModalScroll = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0 12px;
  margin: 0 -12px;

  ${rwd.Large`
    padding: 0 32px;
    margin: 0 -32px;
  `}

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.cGrey};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.cWarning};
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: ${(props) => props.theme.cWarningDark};
  }
`

export const ModalSection = styled.div`
  & + & {
    padding-top: 40px;

    ${rwd.Medium`
      padding-top: 53px;
    `}
  }
`
