import styled from 'styled-components'
import rwd from 'styles/rwd'

const Text = styled.div`
  display: block;

  ${(props) => props.fullWidth && 'width: 100%;'}

  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}

  h1 {
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 22px;
    line-height: 1.18;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-top: 1em;
    margin-bottom: 32px;

    ${rwd.Medium`
      font-size: 34px;
      letter-spacing: 1.5px;
      margin-bottom: 45px;
    `}
  }

  h2 {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 34px;
    line-height: 1.18em;
    letter-spacing: 1.5px;
    margin-top: 1em;
    margin-bottom: 0.18em;

    ${rwd.Medium`
      font-size: 42px;
    `}

    ${rwd.Large`
      font-size: 50px;
    `}
  }

  h4 {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 22px;
    line-height: 1.27em;
    letter-spacing: 0.8px;
    margin-top: 1em;
    margin-bottom: 0.27em;
    opacity: 0.5;

    ${rwd.Medium`
      font-size: 34px;
    `}
  }

  h3 {
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 22px;
    line-height: 1.27em;
    letter-spacing: 0.8px;
    margin-top: 1em;
    margin-bottom: 0.27em;
    text-transform: uppercase;

    ${rwd.Medium`
      font-size: 34px;
    `}
  }

  h5 {
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 22px;
    line-height: 1.27em;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }

  p {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.6px;
    margin: 1em 0;

    ${rwd.XLarge`
      font-size: 20px;
    `}

    a {
      text-decoration: underline;
      transition: color 0.2s;
      color: ${(props) => props.theme.cPrimary};

      &:hover {
        color: ${(props) => props.theme.cText};
      }
    }
  }

  ul {
    margin: 16px;

    li {
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: 0.6px;
    }

    @media screen and (max-width: 479px) {
      padding-left: 15px;
    }
  }

  a {
    text-decoration: underline;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  div {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5em;
    letter-spacing: 0.6px;
  }
`

const Caption = styled.p`
  &&& {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 9px;
    line-height: 1.78em;
    letter-spacing: 0.4px;

    ${rwd.Large`
      font-size: 12px;
    `}

    ${(props) => props.center && 'text-align: center;'}
    ${(props) => props.link && 'margin-top: 36px;'}
    
    a {
      text-decoration: underline;
      transition: color 0.2s;
      color: ${(props) => props.theme.cPrimary};

      &:hover {
        color: ${(props) => props.theme.cText};
      }
    }
  }
`

Text.Caption = Caption

export default Text
