import React from 'react'
import {
  Background,
  Button,
  FAQ,
  SectionTitle,
  ComponentWrapper,
  Row,
  Column,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import backgroundImage from 'static/images/covers/rocket.png'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import { DisplayOnBreakpoint } from 'styles/rwd'

const RegisterEthereumUnSupPage = ({ location }) => {
  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background src={backgroundImage} mobileRatio="16:9" />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        <ComponentWrapper>
          <Text>
            <h3>Unsupported</h3>
            <p>
              {location && location.state && location.state.message
                ? location.state.message
                : 'Something went wrong, please start again.'}
            </p>
          </Text>
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <Button
            onClick={() => {
              deleteRedirectCookieAndNavigate()
            }}
          >
            Continue
          </Button>
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>

        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: 'What went wrong?',
                content:
                  'We use MetaMask and Ledger wallets to link to the Ethereum blockchain. Unfortunately they are not yet supported by all browsers and devices.',
              },
              {
                title: "I don't have an Ethereum address yet, what now?",
                content:
                  "Don't worry, you can create one using MetaMask in minutes. However, you will need to switch to Chrome, Firefox, Opera or Brave browser on a desktop device first.",
              },
              {
                title: 'I already have an Ethereum address, what now?',
                content:
                  "If you already have a Ledger hardware wallet you’d like to use, you will need Google Chrome on a desktop device to proceed. If you already have MetaMask, switch to the browser in which it's installed. Log in from our homepage using that browser, and we will guide you through the process from there. ",
              },
              {
                title: "What if I'd like to do this later?",
                content:
                  'You can always skip this step and complete it from the Checklist in your Investor Dashboard at a later point in time. However, you will not be able to invest before doing so.',
              },
            ]}
          />
        </ComponentWrapper>
      </Column>
    </Row>
  )
}

export default RegisterEthereumUnSupPage
