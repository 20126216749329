import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Text from 'components/Text/Text.styles'
import {
  Button,
  DoubleButtons,
  FAQ,
  SectionTitle,
  ComponentWrapper,
  ComponentScroll,
} from '@stokr/components-library'

import { ModalWrapper } from 'components/Modal/Modal.styles'
import { supportsU2F, isMetamaskInstalled } from 'api/StokrWeb3'

//import { graphql } from "gatsby";

const EthIntro = ({ data, setWallet, changeStep }) => {
  const [showRefreshButton, setShowRefreshButton] = useState(false)

  return (
    <ModalWrapper>
      <ComponentWrapper>
        <SectionTitle>Register Ethereum address</SectionTitle>
      </ComponentWrapper>

      <ComponentScroll>
        <ComponentWrapper noPaddingVertical>
          <Text>
            <h3>Choose your wallet</h3>
            <p>
              {
                'In the next couple of steps we’ll guide you through registering an Ethereum address from your Ledger or MetaMask wallet.'
              }
            </p>
            <p>
              {
                'Before chosing your wallet please make sure you are using the right browser and have the right plug-ins installed. Please have a look at the FAQs below for more information.'
              }
            </p>
          </Text>
        </ComponentWrapper>
        {/* <ComponentWrapper noPaddingBottom>
        <FAQ
          items={[
            {
              title: "Which wallet should i use?",
              content:
                "Aliquam orci nibh, convallis eu fringilla vel, pharetra non libero. Donec feugiat vehicula sem non sagittis. Fusce vitae purus nulla. Curabitur venenatis augue in augue venenatis placerat non a libero. Quisque facilisis porta libero ac aliquam. Vestibulum ipsum mauris, pretium sed felis et, posuere ultricies purus. Aliquam aliquet arcu a tincidunt mattis. Fusce a vestibulum magna."
            }
          ]}
        />
      </ComponentWrapper> */}
        <ComponentWrapper>
          <DoubleButtons>
            <Button
              onClick={() => {
                setWallet('ledger')
                changeStep()
              }}
              disabled={!supportsU2F()}
            >
              Ledger
            </Button>
            {showRefreshButton ? (
              <Button
                onClick={() => {
                  window.location.reload()
                }}
              >
                Refresh
              </Button>
            ) : (
              <Button
                onClick={() => {
                  if (isMetamaskInstalled()) {
                    setWallet('metamask')
                    changeStep()
                  } else {
                    window.open('https://metamask.io', '_blank')
                    setShowRefreshButton(true)
                  }
                }}
                // disabled={!isMetamaskInstalled()}
              >
                {isMetamaskInstalled() ? 'Metamask' : 'Get Metamask'}
              </Button>
            )}
          </DoubleButtons>
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>
        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: 'Which wallet should I use?',
                content:
                  'Metamask is a software wallet in the form of a browser extension that can be installed on a compatible browser on the spot. Hardware wallets like Ledger are considered more secure for being physical devices that store your private key(s) offline.',
              },
              {
                title: "I don't have an Ethereum wallet, what now?",
                content:
                  'You can install the MetaMask wallet and create an Ethereum address in minutes. Just go to metamask.io and download the browser extension to set-up your wallet. (Chrome, Firefox, Opera or Brave browser required). Afterwards, you will need to refresh this page to proceed. ',
              },
              {
                title: 'What is a wallet?',
                content:
                  'A wallet is a hardware device or browser interface to manage and protect your private key and as a result the transactions you are making on the blockchain. ',
              },
            ]}
          />
        </ComponentWrapper>
      </ComponentScroll>
    </ModalWrapper>
  )
}

EthIntro.propTypes = {
  setWallet: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
}

export default EthIntro

/*
export const query = graphql`
  query CheckQuery {
    check {
      supportsU2F
    }
  }
`;
*/
