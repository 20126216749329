import Index from 'pages/index'
import NotFoundPage from 'pages/404'
import SignupFlowRoutes from './routes'

const routes = [
  {
    path: '/',
    element: <Index />,
  },
  ...SignupFlowRoutes,
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

export default routes
