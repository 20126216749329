import React from 'react'
import {
  Background,
  Button,
  ComponentWrapper,
  Row,
  Column,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import backgroundImage from 'static/images/covers/rocket.png'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import { DisplayOnBreakpoint } from 'styles/rwd'

const RegisterAlgorandPendingPage = () => {
  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background src={backgroundImage} mobileRatio="16:9" />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        <ComponentWrapper>
          <Text>
            <h1>YOUR ALGO ADDRESS IS BEING WHITELISTED</h1>
            <p>
              All Algorand addresses are checked to prevent money laundering on
              STOKR. We'll let you know as soon as yours is on the whitelist.
            </p>
          </Text>
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <Button
            onClick={() => {
              deleteRedirectCookieAndNavigate()
            }}
          >
            Continue
          </Button>
        </ComponentWrapper>
      </Column>
    </Row>
  )
}

export default RegisterAlgorandPendingPage
