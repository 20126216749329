import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from 'styles/global'
import theme from 'styles/theme'
import {
  PageWrapper,
  Footer,
  Header,
  AuthConsumer,
} from '@stokr/components-library'

class Layout extends React.PureComponent {
  render() {
    const { loginHandler, signupHandler, children, footerColor, noFooter } =
      this.props

    return (
      <ThemeProvider theme={{ ...theme }}>
        <PageWrapper>
          <GlobalStyle />
          <AuthConsumer>
            {({ user, avatar, logoutUser }) => (
              <Header
                progress={{}}
                signupFlow={true}
                loginHandler={loginHandler}
                signupHandler={signupHandler}
                //noLogout={noLogout}
                user={user}
                userPhoto={avatar}
                logoutUser={logoutUser}
              />
            )}
          </AuthConsumer>

          {children}
          {!noFooter && <Footer color={footerColor} />}
        </PageWrapper>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  title: PropTypes.string,
  signupFlow: PropTypes.bool,
  loginHandler: PropTypes.func,
  signupHandler: PropTypes.func,
  progress: PropTypes.bool,
  footerColor: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  title: '',
  progress: null,
  signupFlow: false,
  footerColor: 'red',
  loginHandler: () => {},
  signupHandler: () => {},
}

export default Layout
