import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-collapse'
import Text from 'components/Text/Text.styles'
import {
  Button,
  TextLink,
  FAQ,
  SectionTitle,
  ComponentWrapper,
  ComponentScroll,
  CryptoAddressWrapper,
  CryptoAddress,
} from '@stokr/components-library'

import { ModalWrapper } from 'components/Modal/Modal.styles'
import { getLedgerAddresses } from 'api/StokrWeb3'
import Config from 'api/StokrWeb3Config'

class EthAddressLedger extends PureComponent {
  state = {
    selectedAddress: null,
    showAll: false,
    addressPool: undefined,
  }

  async componentDidMount() {
    this.setState({ addressPool: await getLedgerAddresses() })
  }

  onAddressChange = (e) => {
    this.setState({ selectedAddress: e.target.value })
  }

  onRegisterClick = () => {
    const { addressPool, selectedAddress } = this.state
    const { changeStep, setAddressToRegister } = this.props

    for (let i in addressPool) {
      if (addressPool[i].radioId === selectedAddress) {
        setAddressToRegister(addressPool[i].address)
      }
    }

    selectedAddress && changeStep && changeStep()
  }

  toggleShowAll = () => {
    const { showAll } = this.state

    this.setState({
      showAll: !showAll,
    })
  }

  render() {
    const { selectedAddress, showAll, addressPool } = this.state

    return (
      <ModalWrapper>
        <ComponentWrapper>
          <SectionTitle>Register Ethereum address</SectionTitle>
        </ComponentWrapper>

        <ComponentScroll>
          <ComponentWrapper noPaddingTop>
            <Text>
              <h3>Select an ethereum address</h3>
              <p>
                {
                  'On this address you will receive your security tokens. You can also use it to purchase security tokens using Ether, Gemini or Tether. '
                }
              </p>
            </Text>
          </ComponentWrapper>

          {/* <ComponentWrapper>
            <FAQ
              items={[
                {
                  title: "Which address should i choose?",
                  content:
                    "Aliquam orci nibh, convallis eu fringilla vel, pharetra non libero. Donec feugiat vehicula sem non sagittis. Fusce vitae purus nulla. Curabitur venenatis augue in augue venenatis placerat non a libero. Quisque facilisis porta libero ac aliquam. Vestibulum ipsum mauris, pretium sed felis et, posuere ultricies purus. Aliquam aliquet arcu a tincidunt mattis. Fusce a vestibulum magna."
                }
              ]}
            />
          </ComponentWrapper> */}

          {addressPool ? (
            <>
              <ComponentWrapper noPadding>
                {addressPool
                  .slice(
                    Config.ledger.showAccountSlice[0],
                    Config.ledger.showAccountSlice[1],
                  )
                  .map((account, i) => (
                    <CryptoAddress
                      key={account + i}
                      title={account.title}
                      address={account.address}
                      data={{
                        value: account.ether,
                        unit: 'ETH',
                        eqValue: account.euro,
                        eqUnit: '€',
                      }}
                      radio={{
                        id: account.id,
                        name: 'address',
                        value: account.radioId,
                        checked: selectedAddress === account.radioId,
                        onChange: this.onAddressChange,
                        onBlur: () => {},
                      }}
                      wrapped
                      borderTop
                    />
                  ))}
              </ComponentWrapper>

              <Collapse isOpened={showAll}>
                <ComponentWrapper noPadding>
                  {addressPool
                    .slice(
                      Config.ledger.showAdditionalSlice[0],
                      Config.ledger.showAdditionalSlice[1],
                    )
                    .map((account, i) => (
                      <CryptoAddress
                        key={account + i}
                        title={account.title}
                        address={account.address}
                        data={{
                          value: account.ether,
                          unit: 'ETH',
                          eqValue: account.euro,
                          eqUnit: '€',
                        }}
                        radio={{
                          id: account.id,
                          name: 'address',
                          value: account.radioId,
                          checked: selectedAddress === account.radioId,
                          onChange: this.onAddressChange,
                          onBlur: () => {},
                        }}
                        wrapped
                        borderTop
                      />
                    ))}
                </ComponentWrapper>
              </Collapse>

              <ComponentWrapper noPaddingHorizontal noPaddingBottom borderTop>
                <ComponentWrapper noPaddingBottom center>
                  <TextLink as="button" onClick={this.toggleShowAll}>
                    {showAll ? 'view less' : 'view more'}
                  </TextLink>
                </ComponentWrapper>

                <ComponentWrapper center>
                  <Button
                    minWidth="240px"
                    onClick={this.onRegisterClick}
                    disabled={selectedAddress === null}
                  >
                    Register selected address
                  </Button>
                </ComponentWrapper>
              </ComponentWrapper>
            </>
          ) : (
            <ComponentWrapper borderTop borderBottom center>
              <CryptoAddressWrapper
                refresh={'Please wait while we connect to your Ledger'}
              >
                <CryptoAddress
                  title="ethereum address"
                  address={''}
                  data={{
                    value: '',
                  }}
                />
              </CryptoAddressWrapper>
            </ComponentWrapper>
          )}

          <ComponentWrapper noPaddingBottom>
            <SectionTitle>Frequently Asked Questions</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper>
            <FAQ
              items={[
                {
                  title: 'Which address should I choose?',
                  content:
                    "If you're planning to use the address to purchase tokens, choose one with sufficient funds or that you plan to top up. For your security and privacy, it's considered best practice to use more than one address for your crypto investments.",
                },
              ]}
            />
          </ComponentWrapper>
        </ComponentScroll>
      </ModalWrapper>
    )
  }
}

EthAddressLedger.propTypes = {
  changeStep: PropTypes.func.isRequired,
}

export default EthAddressLedger
