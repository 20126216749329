import React, { useContext, useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import RegisterLiquid from './register-liquid'
import RegisterLiquidComplete from './complete'
import { AuthContext } from '@stokr/components-library'
import { useCustomNav } from 'utils/customNavigate'

const RegisterLiquidPage = () => {
  const [showComplete, setShowComplete] = useState(false)
  const navigator = useCustomNav()

  const { user } = useContext(AuthContext)
  const { emailVerified } = user

  useEffect(() => {
    if (!emailVerified) {
      navigator.navigate('/resend-activation-email')
    }
  }, [])

  const renderPages = () => {
    if (showComplete) {
      return <RegisterLiquidComplete />
    }

    return <RegisterLiquid setShowComplete={setShowComplete} />
  }

  return (
    <Layout signupFlow progress>
      <SEO title="Liquid address" />

      {renderPages()}
    </Layout>
  )
}

export default RegisterLiquidPage
