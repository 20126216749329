import fetchData from 'api/fetchData'
import { createContext } from 'react'

export const CheckboxContext = createContext()

const CheckboxProvider = ({ children, ...props }) => {
  const checkCheckbox = async (data) => {
    try {
      const result = await fetchData('compliance/check-checkbox', data)
      return result
    } catch (error) {
      console.log(`Error fetching risk questionnaire: ${error}`)
    }
  }

  const checkCheckboxGroup = async (data) => {
    try {
      const result = await fetchData('compliance/check-checkboxes', data)
      return result
    } catch (error) {
      console.log(`Error fetching risk questionnaire: ${error}`)
    }
  }

  const getCheckbox = async (data) => {
    try {
      const result = await fetchData('compliance/get-checkboxes', data)
      return result
    } catch (error) {
      console.log(`Error fetching risk questionnaire: ${error}`)
    }
  }

  const contextValue = {
    checkCheckbox: (data) => checkCheckbox(data),
    getCheckbox: (data) => getCheckbox(data),
    checkCheckboxGroup,
  }

  return (
    <CheckboxContext.Provider value={contextValue}>
      {children}
    </CheckboxContext.Provider>
  )
}

export default CheckboxProvider
