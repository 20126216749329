import React, { useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import RegisterEthereum from './register-ethereum'
import RegEthFlow from './flow'
import RegEthPending from './pending'
import RegEthUnsupported from './unsupported'
import { showUnsupported } from 'api/StokrWeb3'

const RegisterEthereumPage = () => {
  const [isUnsupported, setIsUnsupported] = useState(false)
  const [showFlow, setShowFlow] = useState(false)
  const [showPending, setShowPending] = useState(false)

  const renderPages = () => {
    if (isUnsupported) {
      return <RegEthUnsupported />
    }

    if (showPending) {
      return <RegEthPending />
    }

    return <RegisterEthereum setShowFlow={setShowFlow} />
  }

  useEffect(() => {
    if (showUnsupported()) {
      setIsUnsupported(true)
    }
  }, [])

  return (
    <Layout signupFlow progress>
      <SEO title="Ethereum Address" />
      {renderPages()}
      <RegEthFlow
        showFlow={showFlow}
        setShowFlow={setShowFlow}
        setShowPending={setShowPending}
      />
    </Layout>
  )
}

export default RegisterEthereumPage
