import Cookies from 'js-cookie'
import redirectUrl from './redirect-url'

export default () => {
  const cookieDomain = {}

  if (process.env.NODE_ENV === 'production') {
    cookieDomain.domain = '.stokr-staging.de'
  }

  Cookies.remove('STOKR_INVESTOR_DASHBOARD_REDIRECT', cookieDomain)
  Cookies.remove('STOKR_INVEST_IN_STO_REDIRECT', cookieDomain)

  if (redirectUrl) window.location.href = redirectUrl
  else {
    window.open('https://dashboard.stokr-staging.de/', '_self')
  }
}
