import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import {
  Background,
  Button,
  TextButton,
  RefreshButton,
  SpanButton,
  FAQ,
  SectionTitle,
  Row,
  Column,
  ComponentWrapper,
  CryptoAddressWrapper,
  CryptoAddress,
  AuthContext,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Input from 'components/Input/Input'
import Form, { FormField, FormError } from 'components/Form/Form'
import RegisterLiquidSteps from 'components/RegisterLiquidSteps/RegisterLiquidSteps'
import ToDoTask from 'components/ToDoList/ToDoListTask'
import { ToDoTaskState } from 'components/ToDoList/ToDoListTask'
import { iconsMap } from 'components/Icon/Icon.style'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import redirectUrl from 'utils/redirect-url'
import { useCustomNav } from 'utils/customNavigate'
import { DisplayOnBreakpoint } from 'styles/rwd'
import { RedirectSpacer } from 'pages/verify-identity/verify-identity'

const LiquidDescription = styled.div`
  li {
    font-size: 20px !important;
    line-height: 1.5em;

    img {
      vertical-align: middle;
      padding-bottom: 2px;
    }
  }
`

const createWalletError = {
  E_WALLET_EXISTS:
    'This liquid address is already linked to your STOKR account',
  E_ADDRESS_EXISTS: 'This liquid address is already used by another account',
}

const RegisterLiquid = ({ setShowComplete }) => {
  const { user, validateGreenWalletId, createWallet, setUser } =
    useContext(AuthContext)
  const { wallets = [] } = user
  const navigator = useCustomNav()

  const [isUpdatingUser, setIsUpdatingUser] = useState(false)
  const [error, setError] = useState('')

  const liquidWallets =
    //  wallets.filter((wallet) => wallet.type === 'liquid' && !wallet.assetIds) ||
    []

  const initialValues = {
    liquidId: '',
  }

  const validationSchema = Yup.object().shape({
    liquidId: Yup.string().required('Oops, this can‘t be blank'),
  })

  const handleOnSubmit = async (e) => {
    setIsUpdatingUser(true)
    const id = e.liquidId

    try {
      const { valid } = await validateGreenWalletId(id)

      if (!valid) {
        setError(
          "This address seems to be incorrect. Please make sure your liquid address (AMP ID) starts with the letters 'GA'",
        )
        setIsUpdatingUser(false)
        return
      }

      const newWallet = await createWallet({
        address: e.liquidId,
        type: 'liquid',
        name: 'liquid address',
      })

      const userCopy = { ...user }
      userCopy.wallets.push(newWallet)
      setUser(userCopy)

      setShowComplete(true)
    } catch (error) {
      const { data = null } = error.response || {}

      if (data && Object.keys(createWalletError).includes(data)) {
        setError(createWalletError[data])
      } else {
        setError('Something went wrong. Try again.')
      }
      setIsUpdatingUser(false)
    }
  }

  const onRefreshClick = () => {
    setError(false)
  }

  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background
            src={
              'https://res.cloudinary.com/stokr/image/upload/f_auto,q_auto/v1677599533/Static/Artboard_7_3_4x_pvpb3y.png'
            }
            mobileRatio="16:9"
            contain
            withPadding
          />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        {!redirectUrl ? (
          <ComponentWrapper noPaddingVertical>
            <TextButton onClick={() => navigator.navigate('/taxid')}>
              Back to tax id
            </TextButton>
          </ComponentWrapper>
        ) : (
          <RedirectSpacer />
        )}

        <ComponentWrapper>
          <Text>
            <h1>
              {liquidWallets.length > 0
                ? 'Liquid address'
                : 'Register your Liquid address'}
            </h1>

            {liquidWallets.length === 0 && <RegisterLiquidSteps noScroll />}
            {liquidWallets.length > 0 && (
              <>
                <p style={{ marginBottom: 0 }}>
                  You have already registered the following liquid address(es):
                </p>
                {liquidWallets.map((wallet) => (
                  <CryptoAddress
                    fontSize={14}
                    key={wallet._id}
                    noHead
                    title=""
                    data={{
                      value: wallet.address,
                      tooltip: true,
                      unit: '',
                      eqValue: '',
                      eqUnit: '',
                    }}
                  />
                ))}
                <p>You can register an additional liquid address below:</p>
              </>
            )}
          </Text>
        </ComponentWrapper>

        {!isUpdatingUser && !error && (
          <ComponentWrapper noPaddingVertical>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleOnSubmit}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => {
                const submitDisabled = !values.liquidId

                return (
                  <Form>
                    <ComponentWrapper noPadding>
                      <FormField>
                        <Input
                          id="liquid-id"
                          name="liquidId"
                          type="input"
                          label={
                            liquidWallets.length > 0
                              ? 'New liquid address '
                              : 'Liquid address (AMP ID)'
                          }
                          value={values.liquidId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.liquidId}
                          touched={!!touched.liquidId}
                        />
                        <FormError show={errors.liquidId && touched.liquidId}>
                          {errors.liquidId}
                        </FormError>
                      </FormField>
                    </ComponentWrapper>

                    <br />

                    <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                      <Button
                        minWidth="230px"
                        type="submit"
                        disabled={submitDisabled || isUpdatingUser}
                      >
                        {liquidWallets.length > 0
                          ? 'ADD new address'
                          : 'REGISTER'}
                      </Button>
                    </ComponentWrapper>
                  </Form>
                )
              }}
            </Formik>
          </ComponentWrapper>
        )}

        {(isUpdatingUser || error) && (
          <ComponentWrapper borderTop borderBottom center>
            <CryptoAddressWrapper
              noFailedIcon
              relative
              refresh={
                isUpdatingUser &&
                'Please wait while we verify your liquid address.'
              }
              failed={error}
            />
          </ComponentWrapper>
        )}

        {error && (
          <ComponentWrapper center noPaddingBottom>
            <RefreshButton onClick={onRefreshClick}>TRY AGAIN</RefreshButton>
          </ComponentWrapper>
        )}

        <ComponentWrapper>
          <TextButton
            onClick={() => {
              if (redirectUrl) {
                deleteRedirectCookieAndNavigate()
              } else {
                navigator.navigate('/risk-questionnaire')
              }
            }}
          >
            {liquidWallets.length > 0 ? 'Continue' : "I'll do this later"}
          </TextButton>
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>

        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: 'What is a liquid address?',
                content:
                  'A liquid address is the address of a wallet related with the liquid blockchain. Your securities issued over the Liquid Network will be stored in your liquid wallet. ',
              },
              {
                title: 'Which wallet should I use?',
                content:
                  'Both wallets perform the exact same function. However, SideSwap is simple to setup and manage.',
              },
              {
                title: 'What if I lose access to my liquid address?',
                content: `No stress! If you're an owner of any securities, you can always ask STOKR for a recovery.`,
              },
              {
                title: `What if I'd like to do this later?`,
                content: `You can always skip this step and complete it at a later point in time. However, you will not be able to invest before doing so.`,
              },
            ]}
          />
        </ComponentWrapper>
      </Column>
    </Row>
  )
}

export default RegisterLiquid
