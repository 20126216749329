import React, { useContext } from 'react'
import Text from 'components/Text/Text.styles'
import { Modal, ModalInner } from 'components/Modal/Modal'
import {
  Button,
  ComponentWrapper,
  ComponentScroll,
  AuthContext,
} from '@stokr/components-library'
import {
  RiskQuestionnaireProvider,
  RiskQuestionnaireConsumer,
} from 'components/RiskQuestionnaire/RiskQuestionnaireContext'
import AgreementItem from 'components/AgreementItem/AgreementItem'
import { ModalWrapper } from 'components/Modal/Modal.styles'
import parse from 'html-react-parser'

const RiskQuestionnaireReviewPage = ({
  showReview,
  setShowReview,
  setShowComplete,
}) => {
  const { updateUser } = useContext(AuthContext)

  const updateSteps = (data) => {
    const steps = {}

    Object.entries(data).map((step) => {
      steps[step[1]._id] = false
      return step
    })
  }

  return (
    showReview && (
      <RiskQuestionnaireProvider
        updateSteps={updateSteps}
        updateUser={updateUser}
      >
        <RiskQuestionnaireConsumer>
          {(riskQuestionnaire) => {
            const {
              waiting,
              error,
              questionnaire,
              sendQuestionnaire,
              success,
            } = riskQuestionnaire

            const questionArr = []

            if (questionnaire !== null) {
              const entries = Object.entries(questionnaire)

              questionArr.push({
                _id: 'intro',
                getHandleClick: (stepController) => () => {
                  stepController.changeStep('intro', 0)
                },
              })

              const l = entries.length
              for (let i = 0; i < l; i += 1) {
                const question = entries[i][1]
                const nextQuestion = entries[i + 1] && entries[i + 1][1]

                questionArr.push({
                  ...question,
                  getHandleClick: (stepController) => () => {
                    stepController.changeStep(question._id, i + 1)
                  },
                  getHandleNextClick: nextQuestion
                    ? (stepController) => () => {
                        stepController.changeStep(
                          nextQuestion._id,
                          i + 2,
                          i + 1,
                        )
                      }
                    : () => () => {
                        sendQuestionnaire()
                      },
                  nextButtonText: nextQuestion ? 'Next' : 'Confirm',
                })
              }
            }

            return (
              <>
                <Modal
                  thin
                  fullscreen
                  isOpen={waiting}
                  onClose={() => setShowReview(false)}
                >
                  <ModalInner noPadding center>
                    <Text center>
                      <h3>Waiting...</h3>
                    </Text>
                  </ModalInner>
                </Modal>

                <Modal
                  thin
                  fullscreen
                  isOpen={error !== null}
                  onClose={() => setShowReview(false)}
                >
                  <ModalInner noPadding center>
                    <Text>
                      <h3>Something went wrong</h3>
                    </Text>
                  </ModalInner>
                </Modal>

                <Modal
                  thin
                  fullscreen
                  isOpen={
                    !waiting && !success && !error && questionnaire !== null
                  }
                  onClose={() => setShowReview(false)}
                >
                  <ModalInner noPaddingHorizontal>
                    {questionnaire === null ? null : (
                      <ModalWrapper>
                        <ComponentScroll>
                          <ComponentWrapper>
                            <Text>
                              <h3>RISK CONSIDERATIONS</h3>
                              <p>
                                “Risk comes from not knowing what you are
                                doing.” - Warren Buffett
                              </p>
                              <p>
                                We can’t make you instantly wise, but we can
                                point you to some of the main risks you will
                                face when investing via STOKR.
                              </p>
                            </Text>
                          </ComponentWrapper>
                          {questionArr
                            .filter((question) => question._id !== 'intro')
                            .map((question) => (
                              <>
                                <ComponentWrapper>
                                  <AgreementItem
                                    title={question.title}
                                    text={parse(question.text)}
                                  />
                                </ComponentWrapper>
                              </>
                            ))}
                        </ComponentScroll>
                      </ModalWrapper>
                    )}
                  </ModalInner>
                </Modal>

                <Modal
                  thin
                  fullscreen
                  isOpen={success}
                  onClose={() => setShowComplete(true)}
                >
                  <ModalInner noPadding center>
                    <ComponentWrapper noPaddingBottom>
                      <Text center>
                        <h3>Success</h3>
                      </Text>
                    </ComponentWrapper>

                    <ComponentWrapper>
                      <Button
                        minWidth="240px"
                        onClick={() => setShowComplete(true)}
                      >
                        Continue
                      </Button>
                    </ComponentWrapper>
                  </ModalInner>
                </Modal>
              </>
            )
          }}
        </RiskQuestionnaireConsumer>
      </RiskQuestionnaireProvider>
    )
  )
}

export default RiskQuestionnaireReviewPage
